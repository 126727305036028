<template>
    <div>
        <div class="row g-2">
        
        <div class="col-md-6">
            <div class="form-group">
                <div>
                <label for="perKeluhan">Keluhan Utama<strong class="text-danger">*</strong></label>
                <b-form-input v-model="isParent.row.arankaa_keluhan_utama" @input="onInput($event, 'keluhan-utama')" @blur="onBlur($event, 'keluhan-utama')" @focus="onFocus($event, 'keluhan-utama')" :disabled="disabled['keluhan-utama']" id="keluhan_utama" :formatter="normalText" type="text" name="perKeluhan" class="form-control" placeholder="Keluhan dari pasien" />
                </div>
                <small class="text-info">*Mohon tuliskan informasi waktu mulai keluhan terjadi</small><br/>

                <p class='m-0 text-muted' v-if="disabled['keluhan-utama']">User lain sedang mengisi...</p>

                <VValidate 
                    name="Keluhan Utama" 
                    v-model="isParent.row.arankaa_keluhan_utama" 
                    :rules="toValidate(isParent.mrValidation.arankaa_keluhan_utama)"
                />
            </div>

            <div class="form-group">
                <div>
                <label for="perRiwayat">Riwayat Penyakit Sekarang</label>
                <b-form-textarea @input="onInput($event, 'catatan-dokter')" @blur="onBlur($event, 'catatan-dokter')" @focus="onFocus($event, 'catatan-dokter')" :disabled="disabled['catatan-dokter']" id="riwayat_penyakit_sekarang" v-model="isParent.row.arankaa_riwayat_penyakit_sekarang" :formatter="normalText"  name="perRiwayat" rows="2" class="form-control" placeholder="Riwayat Penyakit Sekarang"></b-form-textarea>
                </div>

                <p class='m-0 text-muted' v-if="disabled['catatan-dokter']">User lain sedang mengisi...</p>

                <VValidate 
                    name="Riwayat Penyakit Sekarang" 
                    v-model="isParent.row.arankaa_riwayat_penyakit_sekarang" 
                    :rules="toValidate(isParent.mrValidation.arankaa_riwayat_penyakit_sekarang)"
                />
            </div>
        </div>

        <div class="col-md-6">
            <div class="form-group">
                <div>
                <label for="perRiwayat">Riwayat Penyakit Dahulu</label>
                <b-form-textarea @input="onInput($event, 'riwayat-penyakit-dahulu')" @blur="onBlur($event, 'riwayat-penyakit-dahulu')" @focus="onFocus($event, 'riwayat-penyakit-dahulu')" :disabled="disabled['riwayat-penyakit-dahulu']" id="riwayat" v-model="isParent.row.arankaa_riwayat_penyakit" :formatter="normalText"  name="perRiwayat" rows="2" class="form-control" placeholder="Riwayat penyakit dari pasien"></b-form-textarea>
                </div>

                <p class='m-0 text-muted' v-if="disabled['riwayat-penyakit-dahulu']">User lain sedang mengisi...</p>

                <VValidate 
                    name="Riwayat Penyakit" 
                    v-model="isParent.row.arankaa_riwayat_penyakit" 
                    :rules="toValidate(isParent.mrValidation.arankaa_riwayat_penyakit)"
                />
            </div>
        </div>
        <div class="col-12">
            <div class="card mb-0">
            <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Riwayat Pengobatan</h6>
            </div>
            <div class="card-body p-3">
                <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <div>
                        <label for="perRiwayat">Riwayat Pengobatan</label>
                        <b-form-textarea id="riwayat_pengobatan" v-model="isParent.row.arankaa_riwayat_pengobatan" :formatter="normalText"  name="perRiwayat" rows="2" class="form-control" placeholder="Riwayat Pengobatan"></b-form-textarea>
                        </div>

                        <span class="text-warning d-none" id="riwayat_pengobatan-typing">User Lain Sedang Mengisi ...</span>            
                        
                    </div>
                </div>
                <div class="col-md-6">
                    <div>
                    <label for="perRiwayatGunaObat">Riwayat Penggunaan Obat</label>
                        <table class="table table-sm table-bordered table-input">
                            <thead>
                            <tr>
                                <th>Nama Obat</th>
                                <th>Dosis</th>
                                <th>Waktu Penggunaan</th>
                                <th>Aksi</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(v,k) in (isParent.row.arankaa_riwayat_penggunaan_obat||[])" :key="k+'obat'">
                                <td class="input-cell">
                                <b-form-input v-model="isParent.row.arankaa_riwayat_penggunaan_obat[k]['nama']"  :formatter="normalText" type="text" placeholder="Isi Nama Obat" class="form-control form-control-sm" />
                                <VValidate 
                                    :name="'Nama Obat'+(k+1)" 
                                    v-model="isParent.row.arankaa_riwayat_penggunaan_obat[k]['nama']" 
                                    :rules="{required: 1, min: 3, max: 128}"
                                />
                                </td>
                                <td class="input-cell">
                                <b-form-input v-model="isParent.row.arankaa_riwayat_penggunaan_obat[k]['dosis']" :formatter="normalText" type="text" placeholder="Isi Dosis Obat" class="form-control form-control-sm" />
                                <VValidate 
                                    :name="'Dosis Obat'+(k+1)" 
                                    v-model="isParent.row.arankaa_riwayat_penggunaan_obat[k]['dosis']" 
                                    :rules="{required: 1}"
                                />
                                </td>
                                <td class="input-cell">
                                <b-form-input v-model="isParent.row.arankaa_riwayat_penggunaan_obat[k]['penggunaan']" :formatter="normalText" type="text" placeholder="Isi Waktu Penggunaan Obat" class="form-control form-control-sm" />
                                <VValidate 
                                    :name="'Waktu Penggunaan Obat'+(k+1)" 
                                    v-model="isParent.row.arankaa_riwayat_penggunaan_obat[k]['penggunaan']" 
                                    :rules="{required: 1}"
                                />
                                </td>
                                <td class="input-cell text-center">
                                    <a href="javascript:;" @click="(isParent.row.arankaa_riwayat_penggunaan_obat||[]).splice(k,1)" data-popup="tooltip" title="Hapus" class="btn btn-sm btn-icon  text-danger-800 alpha-danger"><i class="icon-trash"></i></a>
                                </td>
                            </tr>
                            </tbody>
                            <tfoot>
                            <tr>
                                <td class="text-center" colspan="4">
                                <a href="javascript:;" @click="addRiwayatPenggunaanObat" class="btn btn-outline-info alpha-info text-blue-800 btn-sm">
                                    <i class="mr-2 icon-plus2"></i>
                                    <span>Tambah</span>
                                </a>
                                </td>
                            </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        <div class="col-12">
            <div class="row">
                <div class="col-md-7">
                    <div class="head_panel_red">
                    <div class="d-flex justify-content-between align-items-center">
                        <h3>ALERGI </h3>
                        <div>
                        <b-form-radio-group
                            id="has_alergi"
                            @change="onInput($event, 'has-alergi')"
                            @input="changeAlergi($event)"
                            :options="isParent.Config.mr.yesNoOpt"
                            v-model="isParent.row.arankaa_has_alergi"
                        />


                        <VValidate 
                            name="Alergi" 
                            v-model="isParent.row.arankaa_has_alergi" 
                            :rules="toValidate(isParent.mrValidation.arankaa_has_alergi)"
                        />
                        </div>
                    </div>
                    </div>
                    
                    <template v-if="isParent.row.arankaa_has_alergi == 'Y'">
                        <table class="table table-sm table-bordered">
                            <thead>
                            <tr>
                                <th width="33%">Jenis</th>
                                <th>Informasi Alergi</th>
                                <th width="64"></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(v,k) in (isParent.row.arankaa_alergi||[])" :key="k+'aler'">
                                <td>
                                    <v-select @open="onFocus($event, 'alergi-jenis', k)" @close="onBlur($event, 'alergi-jenis', k)" @input="onInput($event, 'alergi-jenis', k)" :disabled="disabled['alergi-jenis'][k]" placeholder="Pilih Jenis" v-model="isParent.row.arankaa_alergi[k]['jenis']" :options="Config.mr.jenisAlergi" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                    <p class='m-0 text-muted' v-if="disabled['alergi-jenis'][k]">User lain sedang mengisi...</p>
                                    <VValidate 
                                        :name="'Obat '+(k+1)" 
                                        v-model="isParent.row.arankaa_alergi[k]['jenis']" 
                                        :rules="{required:1}"
                                    />
                                </td>
                                <td>
                                    <vue-bootstrap-autocomplete
                                    @focus="onFocus($event, 'alergi-name', k)"
                                    @blur="onBlur($event, 'alergi-name', k)"
                                    :disabled="disabled['alergi-name'][k]"
                                    v-model="isParent.row.arankaa_alergi[k]['name']"
                                    :data="isParent.mAlergi" @input="searchAlergi(isParent.row.arankaa_alergi[k]['name']);onInput($event, 'alergi-name', k)"
                                    placeholder="Pilih Alergi"
                                    />
                                    <p class='m-0 text-muted' v-if="disabled['alergi-name'][k]">User lain sedang mengisi...</p>
                                    <VValidate 
                                        :name="'Obat '+(k+1)" 
                                        v-model="isParent.row.arankaa_alergi[k]['name']" 
                                        :rules="{required:1}"
                                    />
                                </td>
                                <td>
                                <a href="javascript:;" @click="removeAlergi(k)" data-popup="tooltip" title="Hapus" class="btn btn-sm btn-icon border-danger rounded-round text-danger-800 alpha-danger"><i class="icon-trash"></i></a>
                                </td>
                            </tr>
                            </tbody>
                            <tfoot>
                            <tr>
                                <td colspan="3" class="text-center">
                                <a href="javascript:;" @click="addAlergi()" class="btn btn-sm alpha-info border-info"><i class="icon-plus2 mr-1"></i>Tambah</a>
                                </td>
                            </tr>
                            </tfoot>
                        </table>
                        <span class="text-warning d-none" id="alergi-typing">User Lain Sedang Mengisi ...</span>            
                    </template>
                    <template v-else>
                        <table class="table table-sm table-bordered">
                            <thead>
                            <tr>
                                <th width="33%">Jenis</th>
                                <th>Informasi Alergi</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>
                                    <v-select placeholder="Pilih Jenis" disabled :options="Config.mr.jenisAlergi" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                </td>
                                <td>
                                <input disabled type="text" class="form-control" placeholder="cth. amoxicilin">
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </template>
                </div>
            </div>
        </div>

        <div class="col-12">
            <div class="card mb-0">
                <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Tanda Tanda Vital</h6>
                </div> 
                <div class="card-body p-3">
                    <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                        <label>Tekanan Darah <span class="text-danger">*</span></label>
                        <div class="input-group">
                            <b-form-input id="tekanan_darah_min" @blur="onBlur($event, 'tekanan-darah-min')" @focus="onFocus($event, 'tekanan-darah-min')" @input="onInput($event, 'tekanan-darah-min');isParent.autofill($event,'arankkp_ttv_tekanan_darah_min')" :disabled="disabled['tekanan-darah-min']"
                            :formatter="number" v-model="isParent.row.arankaa_ttv_tekanan_darah_min" type="text" name="name" class="form-control" placeholder="Systole"/>
                            
                            <div class="input-group-append input-group-prepend"><span class="input-group-text">/</span></div>

                            <b-form-input id="tekanan_darah_max" @blur="onBlur($event, 'tekanan-darah-max')" @focus="onFocus($event, 'tekanan-darah-max')" @input="onInput($event, 'tekanan-darah-max');isParent.autofill($event,'arankkp_ttv_tekanan_darah_max')" :disabled="disabled['tekanan-darah-max']" :formatter="number" placeholder="Diastole" v-model="isParent.row.arankaa_ttv_tekanan_darah_max" type="text" class="form-control" />
                            <div class="input-group-append"><span class="input-group-text">mmHG</span></div>
                        </div>
                        
                        <p class='m-0 text-muted' v-if="disabled['tekanan-darah-min'] || disabled['tekanan-darah-max']">User lain sedang mengisi...</p>

                        <VValidate 
                            name="Tekanan Darah Min" 
                            v-model="isParent.row.arankaa_ttv_tekanan_darah_min" 
                            :rules="toValidate(isParent.mrValidation.arankaa_ttv_tekanan_darah_min)"
                        />
                        <VValidate 
                            name="Tekanan Darah Max" 
                            v-model="isParent.row.arankaa_ttv_tekanan_darah_max" 
                            :rules="toValidate(isParent.mrValidation.arankaa_ttv_tekanan_darah_max)"
                        />
                        </div>
                    </div>

                    <div class="col-md-5">
                        <div class="form-group">
                            <label>Nadi <span class="text-danger">*</span></label>
                            <div class="form-row">
                                <div class="col-md-12">
                                <div class="input-group">
                                    <b-form-input id="nadi" @blur="onBlur($event, 'nadi')" @focus="onFocus($event, 'nadi')" @input="onInput($event, 'nadi');isParent.autofill($event,'arankkp_ttv_nadi')" :disabled="disabled['nadi']" :formatter="number" v-model="isParent.row.arankaa_ttv_nadi" type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                                    <div class="input-group-append">
                                        <div style="width: 140px;">
                                        <v-select id="label" @close="onBlur($event, 'nadi-label')" @open="onFocus($event, 'nadi-label')" @input="onInput($event, 'nadi-label');isParent.autofill($event,'arankkp_ttv_label')" :disabled="disabled['nadi-label']" placeholder="Pilih Label" v-model="isParent.row.arankaa_ttv_label" :options="Config.mr.StatusRegular" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <p class='m-0 text-muted' v-if="disabled['nadi'] || disabled['nadi-label']">User lain sedang mengisi...</p>                                        

                            <VValidate 
                                name="Nadi" 
                                v-model="isParent.row.arankaa_ttv_nadi" 
                                :rules="toValidate(isParent.mrValidation.arankaa_ttv_nadi)"
                            />
                            <VValidate 
                                name="Label" 
                                v-model="isParent.row.arankaa_ttv_label" 
                                :rules="toValidate(isParent.mrValidation.arankaa_ttv_label)"
                            />
                        </div>
                    </div>
                    
                    <div class="col-md-3">
                        <div class="form-group">
                        <label>Gula Darah</label>
                        <div class="form-row">
                            <div class="col-md-12">
                            <div class="input-group">
                                <b-form-input id="gula_darah" @blur="onBlur($event, 'gula-darah')" @focus="onFocus($event, 'gula-darah')" @input="onInput($event, 'gula-darah');isParent.autofill($event,'arankkp_ttv_gula_darah')" :disabled="disabled['gula-darah']" :formatter="alphanum" v-model="isParent.row.arankaa_ttv_gula_darah"  type="text" class="form-control" />
                                <div class="input-group-append"><span class="input-group-text">mg/dL</span>
                                </div>
                            </div>
                            </div>
                        </div>

                        <p class='m-0 text-muted' v-if="disabled['gula-darah']">User lain sedang mengisi...</p>
                        
                        <VValidate 
                            name="Gula Darah" 
                            v-model="isParent.row.arankaa_ttv_gula_darah" 
                            :rules="toValidate(isParent.mrValidation.arankaa_ttv_gula_darah)"
                        />
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Pernafasan<span class="text-danger">*</span></label>
                            <div class="input-group">
                                <b-form-input id="pernafasan" @blur="onBlur($event, 'pernafasan')" @focus="onFocus($event, 'pernafasan')" @input="onInput($event, 'pernafasan');isParent.autofill($event,'arankkp_ttv_pernafasan')" :disabled="disabled['pernafasan']" :formatter="number" v-model="isParent.row.arankaa_ttv_pernafasan"  type="text" class="form-control" />
                                <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                            </div>

                            <p class='m-0 text-muted' v-if="disabled['pernafasan']">User lain sedang mengisi...</p>

                            <VValidate 
                                name="Pernafasan" 
                                v-model="isParent.row.arankaa_ttv_pernafasan" 
                                :rules="toValidate(isParent.mrValidation.arankaa_ttv_pernafasan)"
                            />
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="form-group">
                        <label>SPO2</label>
                        <div class="form-row">
                            <div class="col-md-12">
                            <div class="input-group">
                                <b-form-input id="spo2" @blur="onBlur($event, 'spo2')" @focus="onFocus($event, 'spo2')" @input="onInput($event, 'spo2');isParent.autofill($event,'arankkp_ttv_spo2')" :disabled="disabled['spo2']" :formatter="number" v-model="isParent.row.arankaa_ttv_spo2"  type="text" class="form-control" />
                                <div class="input-group-append"><span class="input-group-text">%</span>
                                </div>
                            </div>
                            </div>
                        </div>
                        
                        <p class='m-0 text-muted' v-if="disabled['spo2']">User lain sedang mengisi...</p>
                        <VValidate 
                            name="SPO2" 
                            v-model="isParent.row.arankaa_ttv_spo2" 
                            :rules="toValidate(isParent.mrValidation.arankaa_ttv_spo2)"
                        />
                        </div>
                    </div>

                    <div class="col-md-2">
                        <div class="form-group">
                        <label>Suhu<span class="text-danger">*</span></label>
                        <div class="form-row">

                            <div class="col-md-12">
                            <div class="input-group">
                                <b-form-input id="suhu" @blur="onBlur($event, 'suhu')" @focus="onFocus($event, 'suhu')" @input="onInput($event, 'suhu');isParent.autofill($event,'arankkp_ttv_suhu')" :disabled="disabled['suhu']" :formatter="number" v-model="isParent.row.arankaa_ttv_suhu"  type="text" class="form-control" />
                                <div class="input-group-append"><span class="input-group-text">C</span>
                                </div>
                            </div>
                            </div>
                        </div>

                        <p class='m-0 text-muted' v-if="disabled['suhu']">User lain sedang mengisi...</p>
                        <VValidate 
                            name="Suhu" 
                            v-model="isParent.row.arankaa_ttv_suhu" 
                            :rules="toValidate(isParent.mrValidation.arankaa_ttv_suhu)"
                        />
                        </div>
                    </div>

                    <div class="col-md-2">
                        <div class="form-group">
                        <label>Berat Badan</label>
                        <div class="form-row">

                            <div class="col-md-12">
                            <div class="input-group">
                                <b-form-input id="weight" @blur="onBlur($event, 'berat-badan')" @focus="onFocus($event, 'berat-badan')" :disabled="disabled['berat-badan']" @input="hitungBMI();onInput($event, 'berat-badan');isParent.autofill($event,'arankkp_ttv_weight')" :formatter="number" v-model="isParent.row.arankaa_ttv_weight"  type="text" class="form-control" />
                                <div class="input-group-append"><span class="input-group-text">kg</span>
                                </div>
                            </div>
                            </div>
                        </div>

                        <p class='m-0 text-muted' v-if="disabled['berat-badan']">User lain sedang mengisi...</p>
                        <VValidate 
                            name="Berat Badan" 
                            v-model="isParent.row.arankaa_ttv_weight" 
                            :rules="toValidate(isParent.mrValidation.arankaa_ttv_weight)"
                        />
                        </div>
                    </div>

                    
                    <div class="col-md-2">
                        <div class="form-group">
                        <label>Tinggi</label>
                        <div class="form-row">

                            <div class="col-md-12">
                            <div class="input-group">
                                <b-form-input id="height" @blur="onBlur($event, 'tinggi')" @focus="onFocus($event, 'tinggi')" :disabled="disabled['tinggi']" @input="hitungBMI();onInput($event, 'tinggi');isParent.autofill($event,'arankkp_ttv_height')" :formatter="number" v-model="isParent.row.arankaa_ttv_height"  type="text" class="form-control" />
                                <div class="input-group-append"><span class="input-group-text">cm</span>
                                </div>
                            </div>
                            </div>
                        </div>

                        <p class='m-0 text-muted' v-if="disabled['tinggi']">User lain sedang mengisi...</p>
                        <VValidate 
                            name="Tinggi Badan" 
                            v-model="isParent.row.arankaa_ttv_height" 
                            :rules="toValidate(isParent.mrValidation.arankaa_ttv_height)"
                        />
                        </div>
                    </div>

                    <!--
                    <div class="col-md-3">
                        <div class="form-group">
                        <label>Lingkar Kepala</label>
                        <div class="form-row">

                            <div class="col-md-12">
                            <div class="input-group">
                                <b-form-input id="lingkar_kepala" @blur="onBlur($event, 'lingkar-kepala')" @focus="onFocus($event, 'lingkar-kepala')" :disabled="disabled['lingkar-kepala']" @input="hitungBMI();onInput($event, 'lingkar-kepala');isParent.autofill($event,'arankkp_ttv_lingkar_kepala')"
                                
                                :formatter="number" v-model="isParent.row.arankaa_ttv_lingkar_kepala"  type="text" class="form-control" />
                                <div class="input-group-append"><span class="input-group-text">cm</span>
                                </div>
                            </div>
                            </div>
                        </div>

                        <p class='m-0 text-muted' v-if="disabled['lingkar-kepala']">User lain sedang mengisi...</p>

                        <VValidate 
                            name="Lingkar Kepala" 
                            v-model="isParent.row.arankaa_ttv_lingkar_kepala" 
                            :rules="toValidate(isParent.mrValidation.arankaa_ttv_lingkar_kepala)"
                        />
                        </div>
                    </div>
                    -->

                    <div class="col-md-3">
                        <div class="form-group">
                        <label>BMI</label>
                        <div class="form-row">
                            <div class="col-md-12">
                            <div class="input-group">
                                <b-form-input disabled v-model="isParent.row.arankaa_ttv_bmi"  type="text" class="form-control" />
                                <div class="input-group-append"><span class="input-group-text">m2</span>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div class="col-md-3" v-if="isParent.row.ap_usia <= 15">
                        <div class="form-group">
                        <label>Luas Permukaan Tubuh Anak</label>
                        <div class="input-group">
                            <b-form-input id="permukaan_anak" @blur="onBlur($event, 'permukaan-anak')" @focus="onFocus($event, 'permukaan-anak')" @input="onInput($event, 'permukaan-anak');isParent.autofill($event,'arankkp_ttv_luas_permukaan_anak')" :disabled="disabled['permukaan-anak']" :formatter="number" v-model="isParent.row.arankaa_ttv_luas_permukaan_anak"  type="text" class="form-control" />
                            <div class="input-group-append"><span class="input-group-text">m<sup>2</sup></span></div>
                        </div>
                        </div>
                        
                        <p class='m-0 text-muted' v-if="disabled['permukaan-anak']">User lain sedang mengisi...</p>
                    </div>

                    <div class="col-md-3">
                        <div class="form-group">
                        <label>Kesadaran</label>
                        <button type="button" @click="isOpenTS=true" class="btn btn-light mr-3">Isi Tingkat Kesadaran</button>
                        <template v-if="isParent.row.aranktk_nilai_gcs">
                            <div class="">
                            <span class="font-weight-semibold">Nilai GCS: {{tingkatKesadaranTotal}}</span>
                            <span v-if="tingkatKesadaranTotal >= 14" class="font-weight-semibold border-left ml-2 pl-2"> Composmentis</span> 
                            <span v-else-if="tingkatKesadaranTotal >= 12 && tingkatKesadaranTotal <= 13" class="font-weight-semibold border-left ml-2 pl-2">Apatis</span>
                            <span v-else-if="tingkatKesadaranTotal >= 10 && tingkatKesadaranTotal <= 11" class="font-weight-semibold border-left ml-2 pl-2">Delirium</span>
                            <span v-else-if="tingkatKesadaranTotal >= 7 && tingkatKesadaranTotal <= 9" class="font-weight-semibold border-left ml-2 pl-2">Somonolen</span>
                            <span v-else-if="tingkatKesadaranTotal >= 5 && tingkatKesadaranTotal <= 6" class="font-weight-semibold border-left ml-2 pl-2">Sopor</span>
                            <span v-else-if="tingkatKesadaranTotal >= 4 && tingkatKesadaranTotal <= 4" class="font-weight-semibold border-left ml-2 pl-2">Semi-Comma</span>
                            <span v-else class="font-weight-semibold border-left ml-2 pl-2">Coma</span>
                            </div>
                            
                            <div class="">
                                <span class="font-weight-semibold mr-3">E : {{isParent.row.aranktk_response_mata||"-"}}</span>
                                <span class="font-weight-semibold mr-3">M : {{isParent.row.aranktk_response_motorik||"-"}}</span>
                                <span class="font-weight-semibold mr-3">V : {{isParent.row.aranktk_response_verbal||"-"}}</span>
                            </div>
                        </template>
                        
                        <VValidate 
                            name="Kesadaran" 
                            v-model="isParent.row.aranktk_nilai_gcs" 
                            :rules="{required : 1}"
                        />
                        </div>
                    </div>

                    <!--
                    <div class="col-md-3" v-if="!isParent.row.arankaa_ttv_bmi">
                        <div class="form-group">
                            <label>IMT</label>
                            <b-form-input v-model="isParent.row.arankaa_ttv_imt" type="text"
                            class="form-control" />
                        </div>
                    </div>
                    -->

                    </div>
                </div>
            </div>
        </div>

        <!-- OBSYGN START -->
        
        <div class="col-12">
            <div class="card mb-0">
            <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Riwayat Menstruasi</h6>
            </div>
            <div class="card-body p-3">
                <div class="form-row">
                    <div class="col-md-12">
                        <div class="form-group">
                        <label for="GangguanHaid">Gangguan Haid <strong class="text-danger">*</strong></label>
                        <b-form-radio-group
                            :options="isParent.Config.mr.ranapKAGangguanHaid"
                            v-model="isParent.row.arankaa_gg_haid"
                        />
                        </div>
                        <VValidate name="Gangguan Haid" v-model="isParent.row.arankaa_gg_haid"
                        :rules="{required: 1}" />
                    </div>
                </div>
                <div class="form-row mb-2">
                    <div class="col-md-6">
                        <b-form-checkbox v-model="isOpenHPHT">
                            Hitung HPL
                        </b-form-checkbox>                        
                    </div>
                </div>
                <div class="form-row">
                    <template>
                    <div class="col-md-4">
                        <div class="form-group">
                        
                        <div>
                        <label for="perHpht">HPHT</label>
                        <div class="input-group mb-3">
                            <datepicker input-class="form-control transparent"
                            placeholder="Pilih Tanggal" @input="changeHPL" class="my-datepicker"
                            calendar-class="my-datepicker_calendar" v-model="isParent.row.arankaa_hpht">
                            </datepicker>
                            <div class="input-group-append calendar-group">
                            <span class="input-group-text" id="basic-addon2"><i
                                class="icon-calendar"></i></span>
                            </div>
                        </div>
                        </div>
                        <VValidate name="HPHT" v-model="isParent.row.arankaa_hpht"
                        :rules="toValidate(isParent.mrValidation.arankaa_hpht)" />

                        </div>
                    </div>
                    <div class="col-md-3" v-if="isOpenHPHT">
                        <div class="form-group">
                        <label for="HPL">Hari Perkiraan Lahir :<strong class="text-danger">*</strong></label>
                        
                        <div class="input-group mb-3">
                            <datepicker input-class="form-control transparent"
                            placeholder="Tanggal HPL" class="my-datepicker"
                            calendar-class="my-datepicker_calendar" 
                            @input="changeHPL2HPHT"
                            v-model="isParent.row.arankaa_hpl">
                            </datepicker>
                            <div class="input-group-append calendar-group">
                            <span class="input-group-text" id="basic-addon2"><i
                                class="icon-calendar"></i></span>
                            </div>
                        </div>
                        <VValidate name="HPL" v-model="isParent.row.arankaa_hpl"
                        :rules="toValidate(isParent.mrValidation.arankaa_hpl)" />

                        </div>
                    </div>
                    <div class="col-md-4" v-if="isOpenHPHT">
                        <div class="form-group">
                        <label for="UmurKehamilan">Umur Kehamilan :<strong class="text-danger">*</strong></label>
                        <div class="input-group">
                            <b-form-input :formatter="normalText" v-model="isParent.row.arankaa_umur_kehamilan_minggu"  type="text" class="form-control"/>
                            <div class="input-group-append"><span class="input-group-text">Minggu</span></div>
                            <b-form-input :formatter="normalText" v-model="isParent.row.arankaa_umur_kehamilan_hari"  type="text" class="form-control"/>
                            <div class="input-group-append"><span class="input-group-text">Hari</span></div>
                        </div>
                        
                        <VValidate 
                            name="Umur Kehamilan Minggu" 
                            v-model="isParent.row.arankaa_umur_kehamilan_minggu" 
                            :rules="toValidate(isParent.mrValidation.arankaa_umur_kehamilan_minggu)"
                        />
                        <VValidate 
                            name="Umur Kehamilan Hari" 
                            v-model="isParent.row.arankaa_umur_kehamilan_hari" 
                            :rules="toValidate(isParent.mrValidation.arankaa_umur_kehamilan_hari)"
                        />
                        </div>
                    </div>
                    </template>
                </div>
            </div>
            </div>
        </div>

        <div class="col-12">
            <div class="card mb-0">
            <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Riwayat Pernikahan </h6>
            </div>
            <div class="card-body p-3">
                <div class="form-row">
                <div class="col-md-5 mb-2">
                    <div class="form-group">
                    <label for="Menikah">Menikah? <strong class="text-danger">*</strong></label>
                    <div>
                        <div class="form-check form-check-inline align-middle">
                        <b-form-radio-group
                            class="custom-block-2"
                            :options="isParent.Config.mr.yesNoOpt"
                            v-model="isParent.row.arankaa_riwayat_nikah"
                        />

                        <VValidate 
                            name="Status Menikah" 
                            v-model="isParent.row.arankaa_riwayat_nikah" 
                            :rules="toValidate(isParent.mrValidation.arankaa_riwayat_nikah)"
                        />
                        
                        <div class="ml-1" style="width: 180px;" v-if="isParent.row.arankaa_riwayat_nikah == 'Y'">
                          <v-select class="col-md-12" placeholder="Pilih Salah Satu" v-model="isParent.row.arankaa_jml_nikah" :options="Config.mr.ranapKAjmlNikah" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                          
                          <VValidate 
                              name="Jumlah Menikah" 
                              v-model="isParent.row.arankaa_jml_nikah" 
                              :rules="toValidate(isParent.mrValidation.arankaa_jml_nikah)"
                          />
                        </div>
                        
                        </div>

                        <div style="margin-left: 33%;" v-if="isParent.row.arankaa_riwayat_nikah == 'Y' && isParent.row.arankaa_jml_nikah == 'Lebih'">
                          <b-form-input style="width: 180px;" :formatter="normalText" v-model="isParent.row.arankaa_ket_nikah"  type="text" class="form-control" placeholder="Lebih dari 2x"/>
                        
                          <VValidate 
                              name="Keterangan Menikah" 
                              v-model="isParent.row.arankaa_ket_nikah" 
                              :rules="toValidate(isParent.mrValidation.arankaa_ket_nikah)"
                          />
                        </div>
                    </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                    <label for="usiaibu">Usia ibu saat Menikah :<strong class="text-danger">*</strong></label>

                    <b-form-input :formatter="numberOnly" v-model="isParent.row.arankaa_usia_saat_menikah"  type="text" class="form-control" placeholder="Lebih dari 2x"/>
                    <VValidate 
                        name="Usia ibu saat Menikah" 
                        v-model="isParent.row.arankaa_usia_saat_menikah" 
                        :rules="toValidate(isParent.mrValidation.arankaa_usia_saat_menikah)"
                    />
                    
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                    <label for="HPL">Lama Pernikahan Saat ini :<strong class="text-danger">*</strong></label>
                    <div class="input-group">
                      <b-form-input :formatter="numberOnly" v-model="isParent.row.arankaa_lama_nikah"  type="text" class="form-control" placeholder="Bulan/Tahun"/>
                      <div class="input-group-append">
                          <div style="width: 140px;">
                            <v-select placeholder="--Pilih Salah Satu--" v-model="isParent.row.arankaa_satuan_nikah" :options="isParent.Config.mr.bulanTahun" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                          </div>
                      </div>
                    </div>
                    
                    <VValidate 
                        name="Satuan Lama Pernikahan Saat ini" 
                        v-model="isParent.row.arankaa_satuan_nikah" 
                        :rules="{required: 1}"
                    />
                    
                    <VValidate 
                        name="Lama Pernikahan Saat ini" 
                        v-model="isParent.row.arankaa_lama_nikah" 
                        :rules="toValidate(isParent.mrValidation.arankaa_lama_nikah)"
                    />
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>

        <div class="col-12">
            <div class="card mb-0">
            <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Riwayat Kehamilan, Persalinan, dan Nifas Yang lalu
                </h6>
            </div>
            <div class="card-body p-3">
                <div class="form-row">
                <div class="col-md-12 mb-2">
                    <table class="table table-sm table-input table-bordered">
                    <thead>
                        <tr>
                        <th>No</th>
                        <th>Tahun Persalinan </th>
                        <th>Tempat Persalinan </th>
                        <th>Umur Kehamilan </th>
                        <th>Jenis Persalinan </th>
                        <th>Penolong </th>
                        <th>Penyulit </th>
                        <th>JK/ BB </th>
                        <th>Keadaan anak saat ini</th>
                        <th>Aksi</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(v,k) in (isParent.row.arankaa_riwayat_kehamilan||[])" :key="k+'obs'">
                        <td>{{k+1}}</td>
                        <td class="input-cell">
                          <b-form-input v-model="isParent.row.arankaa_riwayat_kehamilan[k]['tahun']" type="text" class="form-control" fdprocessedid="p175kj" />
                        </td>
                        <td class="input-cell">
                          <b-form-input v-model="isParent.row.arankaa_riwayat_kehamilan[k]['tempat']" type="text" class="form-control" fdprocessedid="p175kj" />
                        </td>
                        <td class="input-cell">
                          <b-form-input v-model="isParent.row.arankaa_riwayat_kehamilan[k]['umur']" type="text" class="form-control" fdprocessedid="p175kj" />
                        </td>
                        <td class="input-cell">
                          <b-form-input v-model="isParent.row.arankaa_riwayat_kehamilan[k]['jenis']" type="text" class="form-control" fdprocessedid="p175kj" />
                        </td>
                        <td class="input-cell">
                          <b-form-input v-model="isParent.row.arankaa_riwayat_kehamilan[k]['penolong']" type="text" class="form-control" fdprocessedid="p175kj" />
                        </td>
                        <td class="input-cell">
                          <b-form-input v-model="isParent.row.arankaa_riwayat_kehamilan[k]['penyulit']" type="text" class="form-control" fdprocessedid="p175kj" />
                        </td>
                        <td class="input-cell">
                          <b-form-input v-model="isParent.row.arankaa_riwayat_kehamilan[k]['jk']" type="text" class="form-control" fdprocessedid="p175kj" />
                        </td>
                        <td class="input-cell">
                          <b-form-input v-model="isParent.row.arankaa_riwayat_kehamilan[k]['keadaan']" type="text" class="form-control" fdprocessedid="p175kj" />
                        </td>
                        <td class="input-cell text-center">
                          <a href="javascript:;" @click="(isParent.row.arankaa_riwayat_kehamilan||[]).splice(k,1)" v-b-tooltip.hover title="Hapus" class="btn btn-sm btn-icon text-danger-800 alpha-danger mt-1"><i class="icon-trash"></i></a>
                        </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                          <td colspan="9" class="text-center">
                              <a href="javascript:;" @click="addRiwayatKehamilan" class="btn btn-sm alpha-info border-info"><i
                                  class="icon-plus2 mr-1"></i>Tambah</a>
                          </td>
                        </tr>
                    </tfoot>
                    </table>
                </div>
                </div>
            </div>
            </div>
        </div>

        <div class="col-6">
            <div class="row">
            <div class="col-md-12">
                <div class="card mb-0">
                <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Riwayat Penyakit Yang Lalu / Operasi
                    </h6>
                </div>
                <div class="card-body p-3">
                    <div class="form-row">
                    <div class="col-md-12 mb-2">
                        <div class="form-group">
                        <label for="Pernahdirawat">Pernah Dirawat/Operasi :<strong
                            class="text-danger">*</strong></label>

                        <b-form-radio-group
                          :options="isParent.Config.mr.yesNoOpt"
                          v-model="isParent.row.arankaa_pernah_operasi"
                        />
            
                        <VValidate :name="'Pernah Dirawat/Operasi'" v-model="isParent.row.arankaa_pernah_operasi"
                            :rules="toValidate(isParent.mrValidation.arankaa_pernah_operasi)"/>

                        </div>
                        <div class="form-row">
                        <div class="col-md-6" v-if="isParent.row.arankaa_pernah_operasi == 'Y'">
                            <div class="form-group">
                            <label for="kapan">Kapan<strong class="text-danger">*</strong></label>
                            <b-form-input v-model="isParent.row.arankaa_kapan_operasi" type="text" class="form-control" placeholder="Kapan" />
                              
                            <VValidate :name="'Kapan'" v-model="isParent.row.arankaa_kapan_operasi"
                              :rules="toValidate(isParent.mrValidation.arankaa_kapan_operasi)"/>
                            </div>
                        </div>
                        <div class="col-md-6" v-if="isParent.row.arankaa_pernah_operasi == 'Y'">
                            <div class="form-group">
                            <label for="dimana">Dimana:<strong class="text-danger">*</strong></label>
                            
                            <b-form-input v-model="isParent.row.arankaa_dimana_operasi" type="text" class="form-control" placeholder="Dimana" />
                              
                            <VValidate :name="'Dimana'" v-model="isParent.row.arankaa_dimana_operasi"
                              :rules="toValidate(isParent.mrValidation.arankaa_dimana_operasi)"/>
                            </div>
                        </div>
                        <div class="col-md-8" v-if="isParent.row.arankaa_pernah_operasi == 'Y'">
                            <div class="form-group">
                            <label for="keterangan">Keterangan :</label>
                            
                            <b-form-textarea v-model="isParent.row.arankaa_keterangan_operasi" type="text" class="form-control" placeholder="Keterangan" />
                              
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div class="col-12">
                <div class="card mb-0">
                <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Riwayat Keluarga Berencana
                    </h6>
                </div>
                <div class="card-body p-3">
                    <div class="form-row">
                    <div class="col-md-6">
                        <div class="form-group">
                        <label for="metodekb">Metode KB</label>
                        <b-form-input v-model="isParent.row.arankaa_metode_kb" type="text" class="form-control" placeholder="Metode KB" />
                        <VValidate :name="'Metode KB'" v-model="isParent.row.arankaa_metode_kb"
                          :rules="toValidate(isParent.mrValidation.arankaa_metode_kb)"/>

                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                        <label for="lama-pemakaian">Lama pemakaian</label>
                        <b-form-input v-model="isParent.row.arankaa_lama_pemakaian" type="text" class="form-control" placeholder="Lama Pemakaian" />
                        <VValidate :name="'Lama Pemakaian'" v-model="isParent.row.arankaa_lama_pemakaian"
                          :rules="toValidate(isParent.mrValidation.arankaa_lama_pemakaian)"/>

                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="form-group">
                        <label for="Komplikasi">Komplikasi</label>
                        <div>
                          <b-form-radio-group
                            :options="isParent.Config.mr.ranapKAKomplikasi"
                            v-model="isParent.row.arankaa_komplikasi"
                          />
                          <VValidate :name="'Komplikasi'" v-model="isParent.row.arankaa_komplikasi"
                          :rules="toValidate(isParent.mrValidation.arankaa_komplikasi)"/>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>

        </div>
        
        <div class="col-6">
            <div class="card mb-0">
            <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Riwayat Penyakit Keluarga dan Gynecologi
                </h6>
            </div>
            <div class="card-body p-3">
                <div class="form-row">
                    <div class="col-md-6">
                        <b-form-checkbox-group
                            class="checkbox-block"
                            v-model="isParent.row.arankaa_riwayat_penyakit_keluarga"
                            :options="isParent.mGynecologi1"
                            name="riwayat_penyakit"
                        ></b-form-checkbox-group>
                    </div>
                    <div class="col-md-6">
                        <b-form-checkbox-group
                            class="checkbox-block"
                            v-model="isParent.row.arankaa_riwayat_penyakit_keluarga"
                            :options="isParent.mGynecologi2"
                            name="riwayat_penyakit"
                        ></b-form-checkbox-group>
                    </div>
                </div>
                <div class="form-row" v-if="(isParent.row.arankaa_riwayat_penyakit_keluarga||[]).indexOf('Lainnya') != -1">
                  <b-form-input v-model="isParent.row.arankaa_riwayat_penyakit_keluarga_lainnya" type="text" class="form-control" placeholder="Riwayat Penyakit Lainnya"/>
                  
                  <VValidate :name="'Riwayat Penyakit Keluarga Lainnya'" v-model="isParent.row.arankaa_riwayat_penyakit_keluarga_lainnya"
                    :rules="toValidate(isParent.mrValidation.arankaa_riwayat_penyakit_keluarga_lainnya)"/>
                </div>
            </div>
            </div>
        </div>

        <div class="col-12">
            <div class="card mb-0">
            <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Keadaan Umum</h6>
            </div>
            <div class="card-body p-3">
                <div class="row">
                <div class="col-md-auto">
                    <label for="Mata">Mata <small class="txt_mandatory">*</small></label>
                    <div>
                      <b-form-group>
                        <b-form-checkbox-group
                            class="checkbox-block"
                            v-model="isParent.row.arankaa_keadaan_umum_mata"
                            :options="isParent.mKURanapMata"
                        ></b-form-checkbox-group>
                      </b-form-group>
                      
                      <VValidate 
                        name="Mata" 
                        v-model="isParent.row.arankaa_keadaan_umum_mata" 
                        :rules="toValidate(isParent.mrValidation.arankaa_keadaan_umum_mata)"
                      />
                    </div>
                </div>

                <div class="col-md-auto">
                    <label for="Payudara">Payudara <small class="txt_mandatory">*</small></label>
                    <div>
                      <b-form-group>
                        <b-form-checkbox-group
                            class="checkbox-block"
                            v-model="isParent.row.arankaa_keadaan_umum_payudara"
                            :options="isParent.mKURanapPayudara"
                        ></b-form-checkbox-group>
                      </b-form-group>
                      
                      <VValidate 
                        name="Payudara" 
                        v-model="isParent.row.arankaa_keadaan_umum_payudara" 
                        :rules="toValidate(isParent.mrValidation.arankaa_keadaan_umum_payudara)"
                      />
                    </div>
                </div>

                <div class="col-md-auto">
                    <label for="Ekstremitas">Ekstremitas <small class="txt_mandatory">*</small></label>
                    <b-form-group>
                      <b-form-checkbox-group
                          class="checkbox-block"
                          v-model="isParent.row.arankaa_keadaan_umum_ekstremitas"
                          :options="isParent.mKURanapEkstrimitas"
                      ></b-form-checkbox-group>
                    </b-form-group>
                    
                    <VValidate 
                      name="Ekstremitas" 
                      v-model="isParent.row.arankaa_keadaan_umum_ekstremitas" 
                      :rules="toValidate(isParent.mrValidation.arankaa_keadaan_umum_ekstremitas)"
                    />
                </div>


                <div class="col-md-auto">
                    <label for="SistemKardio">Sistem kardiopulmo <small class="txt_mandatory">*</small></label>
                    
                    <b-form-group>
                      <b-form-checkbox-group
                          class="checkbox-block"
                          v-model="isParent.row.arankaa_keadaan_umum_sistem_kardio"
                          :options="isParent.mKURanapKardio"
                      ></b-form-checkbox-group>
                    </b-form-group>
                    
                    <VValidate 
                      name="Ekstremitas" 
                      v-model="isParent.row.arankaa_keadaan_umum_sistem_kardio" 
                      :rules="toValidate(isParent.mrValidation.arankaa_keadaan_umum_sistem_kardio)"
                    />
                </div>

                <!--
                <div class="col-md-4">
                    <label for="Genital">Genital <small class="txt_mandatory">*</small></label>
                    
                    <b-form-group>
                      <b-form-checkbox-group
                          class="checkbox-block"
                          v-model="isParent.row.arankaa_keadaan_umum_genital"
                          :options="isParent.mKURanapKardio"
                      ></b-form-checkbox-group>
                    </b-form-group>
                    
                    <VValidate 
                      name="Genital" 
                      v-model="isParent.row.arankaa_keadaan_umum_genital" 
                      :rules="toValidate(isParent.mrValidation.arankaa_keadaan_umum_genital)"
                    />
                </div>
                -->
                </div>
            </div>
            </div>
        </div>

        <div class="col-12">
            <div class="card mb-0">
            <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Pemeriksaan Obstetri</h6>
            </div>
            <div class="card-body p-3">
                <div class="row gy-2">
                    <div class="col-md-2">
                        <div class="form-group">
                        <label>TFU</label>

                        <div class="input-group">
                        <b-form-input v-model="isParent.row.arankaa_tfu" @input="hitungBeratJanin()" type="text" class="form-control" placeholder="TFU" />
                        <div class="input-group-append input-group-prepend"><span class="input-group-text">cm</span></div>
                        </div>

                        <VValidate :name="'TFU'" v-model="isParent.row.arankaa_tfu"
                        :rules="toValidate(isParent.mrValidation.arankaa_tfu)"/>
                        

                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                        <label>Masuk Panggul</label>
                        <v-select placeholder="Pilih Salah Satu" @input="hitungBeratJanin()" v-model="isParent.row.arankaa_masuk_panggul" :options="isParent.Config.mr.ranapKAMasukPanggul" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                        <VValidate :name="'Masuk Panggul'" v-model="isParent.row.arankaa_masuk_panggul"
                        :rules="toValidate(isParent.mrValidation.arankaa_masuk_panggul)"/>

                        </div>

                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                        <label>Tafsiran Berat Janin</label>
                        
                        <div class="input-group">
                          <b-form-input v-model="isParent.row.arankaa_berat_janin" type="text" class="form-control" placeholder="Tafsiran Berat Janin" />
                          <div class="input-group-append input-group-prepend"><span class="input-group-text">gram</span></div>
                        </div>

                        <VValidate :name="'Tafsiran Berat Janin'" v-model="isParent.row.arankaa_berat_janin"
                        :rules="toValidate(isParent.mrValidation.arankaa_berat_janin)"/>
                        
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                        <label>His / Kontraksi</label>
                        
                        <div class="input-group">                        
                        <b-form-input v-model="isParent.row.arankaa_his" type="text" class="form-control" placeholder="His" />
                        <div class="input-group-append input-group-prepend"><span class="input-group-text">x / 10' /</span></div>
                        <b-form-input v-model="isParent.row.arankaa_kontraksi" type="text" class="form-control" placeholder="Kontraksi" />
                        </div>

                        <VValidate :name="'His'" v-model="isParent.row.arankaa_his"
                        :rules="toValidate(isParent.mrValidation.arankaa_his)"/>

                        <VValidate :name="'Kontraksi'" v-model="isParent.row.arankaa_kontraksi"
                        :rules="toValidate(isParent.mrValidation.arankaa_kontraksi)"/>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                        <label>VT</label>
                        <b-form-input v-model="isParent.row.arankaa_vt" type="text" class="form-control" placeholder="VT" />
                        <VValidate :name="'VT'" v-model="isParent.row.arankaa_vt"
                        :rules="toValidate(isParent.mrValidation.arankaa_vt)"/>
                        </div>
                    </div>
                </div>

                <div class="row gy-2">
                    <div class="col-md-2">
                        <label for="Inspeksi ">Inspeksi </label>
                        <b-form-group>
                        <b-form-checkbox-group
                            class="checkbox-block"
                            v-model="isParent.row.arankaa_inpeksi"
                            :options="Config.mr.ranapKAInspeksi"
                        ></b-form-checkbox-group>
                        </b-form-group>
                        
                        <VValidate 
                        name="Inspeksi" 
                        v-model="isParent.row.arankaa_inpeksi" 
                        :rules="toValidate(isParent.mrValidation.arankaa_inpeksi)"
                        />

                        <div class="form-group" v-if="(isParent.row.arankaa_inpeksi||[]).indexOf('Lainnya') != -1">
                        <b-form-input v-model="isParent.row.arankaa_inpeksi_lainnya" type="text" class="form-control" placeholder="Sebutkan" />
                        <VValidate :name="'Sebutkan'" v-model="isParent.row.arankaa_inpeksi_lainnya"
                        :rules="toValidate(isParent.mrValidation.arankaa_inpeksi_lainnya)"/>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <label for="Presentasi ">Presentasi </label>
                        <b-form-group>
                        <b-form-checkbox-group
                            class="checkbox-block"
                            v-model="isParent.row.arankaa_presentasi"
                            :options="Config.mr.ranapKAPresentasi"
                        ></b-form-checkbox-group>
                        </b-form-group>
                        
                        <VValidate 
                        name="Presentasi" 
                        v-model="isParent.row.arankaa_presentasi" 
                        :rules="toValidate(isParent.mrValidation.arankaa_presentasi)"
                        />
                    </div>

                    <div class="col-md-8 wrap_line">
                        <div class="row mt-2" v-for="(v,k) in (isParent.row.arankaa_auskultasi_data||[])" :key="k+'aus'">
                            <div class="col-md-3">
                                <div class="form-group">
                                <label>Auskultasi</label>
                                <b-form-input v-model="isParent.row.arankaa_auskultasi_data[k]['auskultasi']" type="text" class="form-control" placeholder="Auskultasi" />
                                <!--
                                <VValidate :name="'Auskultasi #'+(k+1)" v-model="isParent.row.arankaa_auskultasi_data[k]['auskultasi']"
                                :rules="{required: 1, min: 3, max: 256}" />
                                -->
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                <label>Bagian terendah janin</label>
                                <v-select placeholder="Bagian Terendah Janin" 
                                v-model="isParent.row.arankaa_auskultasi_data[k]['janin']" :options="isParent.Config.mr.bagianTerendahJanin" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                <!--                                
                                <VValidate :name="'Bagian terendah janin #'+(k+1)" v-model="isParent.row.arankaa_auskultasi_data[k]['janin']"
                                :rules="{required: 1, min: 3, max: 256}"/>
                                -->
                                </div>
                            </div>
                            <div class="col-md-3">
                                <label for="LetakPunggung ">Letak Punggung </label>
                                <b-form-group>
                                <b-form-checkbox-group
                                    class="checkbox-block"
                                    v-model="isParent.row.arankaa_auskultasi_data[k]['letak_punggung']"
                                    :options="Config.mr.ranapKALetakPunggung"
                                ></b-form-checkbox-group>
                                </b-form-group>
                                
                                <!--
                                <VValidate 
                                :name="'Letak Punggung #'+(k+1)" 
                                v-model="isParent.row.arankaa_auskultasi_data[k]['letak_punggung']" 
                                :rules="{required: 1, min: 3, max: 256}"
                                />
                                -->
                            </div>
                            <div class="col-md-2" v-if="(isParent.row.arankaa_auskultasi_data||[]).length > 1">
                                <button type="button" class="btn btn-light" 
                                @click="isParent.row.arankaa_auskultasi_data.splice(k,1)">Hapus</button>
                            
                            </div>                            
                        </div>
                        <button type="button" @click="addAkultasi" class="btn btn-light mr-3">Tambah Auskultasi</button>
                    </div>

                </div>
            </div>
            </div>
        </div>


        <div class="col-12">
            <div class="card mb-0">
            <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Pemeriksaan Penunjang</h6>
            </div>
            <div class="card-body p-3">
                <div class="row">
                    <div class="col-md-12">
                        <h6>Hasil Pemeriksaan Lab</h6>
                        <table class="table table-bordered table-striped table-sm patient-table">
                            <thead>
                            <tr>
                                <th>Hasil Pemeriksaan </th>
                                <th>Hasil</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(v,k) in (isParent.dataHasilLab||[])" :key="k+'hasil'">
                                <td>{{v.value}}</td>
                                <td>
                                    <div class="result_tab form-group mb-0">
                                    <a class="btn_view" :href="isParent.uploader(v.file)" target="_blank"><i class="icon-file-download"></i></a>
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="!(isParent.dataHasilLab||[]).length">
                                <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="col-md-12 mt-3">
                        <h6>Hasil Pemeriksaan Radiologi</h6>
                        <table class="table table-bordered table-striped table-sm patient-table">
                            <thead>
                            <tr>
                                <th>Hasil Pemeriksaan </th>
                                <th>Hasil</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(v,k) in (isParent.dataHasilRadiologi||[])" :key="k+'hasil'">
                                <td>{{v.value}}</td>
                                <td>
                                    <div class="result_tab form-group mb-0">
                                    <a class="btn_view" :href="isParent.uploader(v.file)" target="_blank"><i class="icon-file-download"></i></a>
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="!(isParent.dataHasilRadiologi||[]).length">
                                <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-md-12">
                        <h6>Hasil Pemeriksaan Lab Dokter</h6>
                        <table class="table table-bordered table-striped table-sm patient-table">
                            <thead>
                            <tr>
                                <th>Hasil Pemeriksaan </th>
                                <th>Hasil</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(v,k) in (isParent.dataHasilLabDokter||[])" :key="k+'hasil'">
                                <td>{{v.value}}</td>
                                <td>
                                    <div class="result_tab form-group mb-0">
                                    <a class="btn_view" :href="isParent.uploader(v.file)" target="_blank"><i class="icon-file-download"></i></a>
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="!(isParent.dataHasilLabDokter||[]).length">
                                <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="col-md-12 mt-3">
                        <h6>Hasil Pemeriksaan Radiologi Dokter</h6>
                        <table class="table table-bordered table-striped table-sm patient-table">
                            <thead>
                            <tr>
                                <th>Hasil Pemeriksaan </th>
                                <th>Hasil</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(v,k) in (isParent.dataHasilRadiologiDokter||[])" :key="k+'hasil'">
                                <td>{{v.value}}</td>
                                <td>
                                    <div class="result_tab form-group mb-0">
                                    <a class="btn_view" :href="isParent.uploader(v.file)" target="_blank"><i class="icon-file-download"></i></a>
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="!(isParent.dataHasilRadiologiDokter||[]).length">
                                <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            </div>
        </div>

        <!-- OBSYGN END -->


        <div class="col-12">
            <div class="card mb-0">
            <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Pemeriksaan Fisik</h6>
            </div>
            <div class="card-body p-3">
                <div class="alert alert-info">Silakan pilih/ceklis bagian yang memiliki kelainan</div>
                
                <div class="bg-light card-header c-pointer"
                @click="pemeriksaanFisik = !pemeriksaanFisik"
                :class="pemeriksaanFisik ? null : 'collapsed'"
                :aria-expanded="pemeriksaanFisik ? 'true' : 'false'"
                aria-controls="GenPartRow">
                <div class="d-flex align-items-center justify-content-between">
                    <span class="card-title font-weight-semibold">Klik untuk
                    {{ pemeriksaanFisik ? "Menyembunyikan" : "Menampilkan" }} Bagian Keadaan
                    Umum</span>
                    <i class="icon-arrow-down22" style="font-size: 20px;"></i>
                </div>
                </div>       
                <b-collapse id="GenPartRow" v-model="pemeriksaanFisik">
                    <div class="row g-0">
                        <div class="col-md-6" v-for="(genCol, genColKey) in oddEvenGenCondition"
                        :key="genColKey+'genCol'">
                        <div class="gen-part" v-for="(vs,ks) in genCol" :key="ks+'sign'">
                            <div
                            class="d-flex align-items-center justify-content-between gen-part-head c-pointer"
                            @click="isParent.row[vs.value+'isOpen'] = !isParent.row[vs.value+'isOpen']"
                            :class="isParent.row[vs.value+'isOpen'] ? null : 'collapsed'">
                            <div class="font-weight-semibold">{{vs.label}}
                                <span class="text-danger" v-if="isParent.row[vs.value] == 'TN'">*</span>
                            </div>
                            <i class="icon-arrow-down22"></i>
                            </div>

                            <b-collapse :id="vs.value" v-model="isParent.row[vs.value+'isOpen']"
                            accordion="accKeadaanUmum">
                            <div class="gen-part-body mt-1">
                                <div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" v-model="isParent.row[vs.value]"
                                    @input="changeVital(vs.value,'N')"
                                    class="custom-control-input" :name="`formType${vs.label}`"
                                    :id="`normalCheck${vs.label}`" value="N">
                                    <label class="custom-control-label"
                                    :for="`normalCheck${vs.label}`">
                                    Normal
                                    </label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" v-model="isParent.row[vs.value]"
                                    @input="changeVital(vs.value,'TN')"
                                    class="custom-control-input" :name="`formType${vs.label}`"
                                    :id="`notNormalCheck${vs.label}`" value="TN">
                                    <label class="custom-control-label"
                                    :for="`notNormalCheck${vs.label}`">
                                    Tidak Normal
                                    </label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" v-model="isParent.row[vs.value]"
                                    class="custom-control-input" :name="`formType${vs.label}`"
                                    :id="`notCheck${vs.label}`" value="TP">
                                    <label class="custom-control-label"
                                    :for="`notCheck${vs.label}`">
                                    Tidak Diperiksa
                                    </label>
                                </div>
                                </div>
                                <div class="gen-input mt-2" v-if="isParent.row[vs.value] !== 'TP'">
                                        <template v-if="vs.value == 'arankaa_vs_saraf' && isParent.row[vs.value] == 'TN'">
                                            <div class="gen-input-saraf" data-select2-id="162">
                                            <div class="mb-2">
                                                <label for="refleksFisiologis">Refleks Fisiologis</label>
                                                <b-textarea
                                                v-model="isParent.row.arankaa_vs_saraf_r_fisiologis" class="form-control"
                                                rows="2"></b-textarea>
                                            </div>
                                            <div class="mb-2">
                                                <label for="refleksPatologis">Refleks Patologis</label>
                                                <b-textarea
                                                v-model="isParent.row.arankaa_vs_saraf_r_patologis" class="form-control"
                                                rows="2"></b-textarea>
                                            </div>

                                            <div class="row g-1">
                                                
                                                <div class="col-md-6">
                                                    <label>Babinski</label>
                                                    <v-select placeholder="Status" 
                                                    v-model="isParent.row.arankaa_vs_saraf_babinski" :options="isParent.Config.mr.positifNegatifOpt" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                                </div>

                                                <div class="col-md-6">
                                                    <label>Lasegue</label>
                                                    <v-select placeholder="Status" 
                                                    v-model="isParent.row.arankaa_vs_saraf_lasegue" :options="isParent.Config.mr.positifNegatifOpt" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                                </div>
                                                
                                                <div class="col-md-6">
                                                    <label>Bregard</label>
                                                    <v-select placeholder="Status" 
                                                    v-model="isParent.row.arankaa_vs_saraf_bregard" :options="isParent.Config.mr.positifNegatifOpt" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                                </div>
                                                
                                                <div class="col-md-6">
                                                    <label>Sicard</label>
                                                    <v-select placeholder="Status" 
                                                    v-model="isParent.row.arankaa_vs_saraf_sicard" :options="isParent.Config.mr.positifNegatifOpt" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                                </div>
                                                
                                                <div class="col-md-6">
                                                    <label>Kaku Duduk</label>
                                                    <v-select placeholder="Status" 
                                                    v-model="isParent.row.arankaa_vs_saraf_kaku_duduk" :options="isParent.Config.mr.positifNegatifOpt" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                                </div>

                                                <div class="col-md-6">
                                                    <label>Kernig Sign</label>
                                                    <v-select placeholder="Status" 
                                                    v-model="isParent.row.arankaa_vs_saraf_kernig_sign" :options="isParent.Config.mr.positifNegatifOpt" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                                </div>

                                                <div class="col-md-6">
                                                    <label>Brudzinski 1</label>
                                                    <v-select placeholder="Status" 
                                                    v-model="isParent.row.arankaa_vs_saraf_brudzinski" :options="isParent.Config.mr.positifNegatifOpt" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                                </div>
                                                
                                                <div class="col-md-6">
                                                    <label>Brudzinski 2</label>
                                                    <v-select placeholder="Status" 
                                                    v-model="isParent.row.arankaa_vs_saraf_brudzinski2" :options="isParent.Config.mr.positifNegatifOpt" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                                </div>

                                            </div>
                                            
                                            </div>
                                        </template>
                                        <template v-else>
                                            <b-textarea
                                                v-model="isParent.row[vs.value+'_text']" class="form-control"
                                                :placeholder="`cth. pembengkakan pada bagian ${vs.label} sebelah kiri`"
                                                rows="2"></b-textarea>

                                            <VValidate :name="`${vs.label}`" v-model="isParent.row[vs.value+'_text']"
                                                :rules="{required:1, min:2, max:512}" />
                                        </template>
                                </div>
                            </div>
                            </b-collapse>
                        </div>
                        </div>
                    </div>
                </b-collapse>
            </div>
            </div>
        </div>
        
        <b-modal v-model="isOpenTS" :title="'Isi Tingkat Kesadaran'" size="xl" ok-only ok-title="Simpan">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header bg-info">
                            <h5 class="card-title font-weight-semibold">Tingkat Kesadaran (GCS)</h5>
                        </div>
                        <div class="card-body">
                            <table class="table table-bordered table-hover">
                            <thead>
                                <tr>
                                <th>Pengkajian</th>
                                <th>Nilai</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td class="table-warning" rowspan="4"><span class="font-weight-semibold">Respons Mata</span></td>
                                
                                <!-- ele pertama hardcode -->
                                <td>
                                <b-form-radio @input="onInput($event, 'response-mata');isParent.autofill($event,'arankkp_tingkat_kesadaran_mata')" v-model="isParent.row.aranktk_response_mata" name="aranktk_response_mata" :value="4">Dapat Membuka Mata Secara Spontan  <span class="text-blue-800 font-weight-semibold">(4)</span></b-form-radio>
                                </td>

                                </tr>
                                <template v-for="(v,k) in (Config.mr.ksResponseMata||[])">
                                    <tr v-if="k != 0" :key="k">
                                    <td>
                                        <b-form-radio @input="onInput($event, 'response-mata');isParent.autofill($event,'arankkp_tingkat_kesadaran_mata')" v-model="isParent.row.aranktk_response_mata" name="aranktk_response_mata" :value="v.value">{{v.text}}<span class="text-blue-800 font-weight-semibold">({{v.value}})</span></b-form-radio>
                                    </td>
                                    </tr>
                                </template>
                            </tbody>
                            <tbody>
                                <tr>
                                <td class="table-warning" rowspan="5"><span class="font-weight-semibold">Respons Verbal</span></td>
                                
                                <td>
                                <b-form-radio @input="onInput($event, 'response-verbal');isParent.autofill($event,'arankkp_tingkat_kesadaran_verbal')" v-model="isParent.row.aranktk_response_verbal" name="aranktk_response_verbal" :value="5">Pasien Sadar dan Merespons Pertanyaan dengan Benar<span class="text-blue-800 font-weight-semibold">(5)</span>
                                </b-form-radio>
                                </td>
                                
                                </tr>
                                <template v-for="(v,k) in (Config.mr.ksResponseVerbal||[])">
                                    <tr v-if="k != 0" :key="k">
                                    <td>
                                        <b-form-radio @input="onInput($event, 'response-verbal');isParent.autofill($event,'arankkp_tingkat_kesadaran_verbal')" v-model="isParent.row.aranktk_response_verbal" name="aranktk_response_verbal" :value="v.value">{{v.text}}<span class="text-blue-800 font-weight-semibold">({{v.value}})</span></b-form-radio>
                                    </td>
                                    </tr>
                                </template>
                            </tbody>
                            <tbody>
                                <tr>
                                <td class="table-warning" rowspan="6"><span class="font-weight-semibold">Respons Motorik</span></td>
                                
                                <td>
                                <b-form-radio @input="onInput($event, 'response-motorik');isParent.autofill($event,'arankkp_tingkat_kesadaran_motorik')" v-model="isParent.row.aranktk_response_motorik" name="aranktk_response_motorik" :value="6">Pasien Dapat Melakukan Gerakan Sesuai Perintah<span class="text-blue-800 font-weight-semibold">(6)</span></b-form-radio>
                                </td>
                                
                                </tr>
                                <template v-for="(v,k) in (Config.mr.ksResponseMotorik||[])">
                                    <tr v-if="k != 0" :key="k">
                                    <td>
                                        <b-form-radio @input="onInput($event, 'response-motorik');isParent.autofill($event,'arankkp_tingkat_kesadaran_motorik')" v-model="isParent.row.aranktk_response_motorik" name="aranktk_response_motorik" :value="v.value">{{v.text}}<span class="text-blue-800 font-weight-semibold">({{v.value}})</span></b-form-radio>
                                    </td>
                                    </tr>
                                </template>
                            </tbody>
                            <tfoot>
                                <tr>
                                <td class="table-info" colspan="9">
                                    <span class="font-weight-semibold">Nilai GCS: {{tingkatKesadaranTotal}}</span>
                                    <span v-if="tingkatKesadaranTotal >= 14" class="font-weight-semibold border-left ml-2 pl-2"> Composmentis</span> 
                                    <span v-else-if="tingkatKesadaranTotal >= 12 && tingkatKesadaranTotal <= 13" class="font-weight-semibold border-left ml-2 pl-2">Apatis</span>
                                    <span v-else-if="tingkatKesadaranTotal >= 10 && tingkatKesadaranTotal <= 11" class="font-weight-semibold border-left ml-2 pl-2">Delirium</span>
                                    <span v-else-if="tingkatKesadaranTotal >= 7 && tingkatKesadaranTotal <= 9" class="font-weight-semibold border-left ml-2 pl-2">Somonolen</span>
                                    <span v-else-if="tingkatKesadaranTotal >= 5 && tingkatKesadaranTotal <= 6" class="font-weight-semibold border-left ml-2 pl-2">Sopor</span>
                                    <span v-else-if="tingkatKesadaranTotal >= 4 && tingkatKesadaranTotal <= 4" class="font-weight-semibold border-left ml-2 pl-2">Semi-Comma</span>
                                    <span v-else class="font-weight-semibold border-left ml-2 pl-2">Coma</span>
                                </td>
                                </tr>
                            </tfoot>
                            </table>

                            <!--
                            <VValidate 
                                name="Response Mata" 
                                v-model="isParent.row.aranktk_response_mata" 
                                :rules="toValidate(isParent.mrValidation.aranktk_response_mata)"
                                class="d-block"
                            />

                            
                            <VValidate 
                                name="Response Verbal" 
                                v-model="isParent.row.aranktk_response_verbal" 
                                :rules="toValidate(isParent.mrValidation.aranktk_response_verbal)"
                                class="d-block"
                            />
                            
                            <VValidate 
                                name="Response Motorik" 
                                v-model="isParent.row.aranktk_response_motorik" 
                                :rules="toValidate(isParent.mrValidation.aranktk_response_motorik)"
                                class="d-block"
                            />
                            -->


                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
        
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')
import Datepicker from 'vuejs-datepicker'


export default{
    extends: GlobalVue,
    components: {
      Datepicker
    },
    computed: {
        isParent(){
            return this.$parent.$parent.$parent.$parent
        },
        oddEvenGenCondition() {
            let odd = this.isParent.Config.mr.configVitalSignRanap.filter((el, index) => index % 2)
            let even = this.isParent.Config.mr.configVitalSignRanap.filter((el, index) => !(index % 2))
            let res = []
            res.push(even)
            res.push(odd)
            return res
        },
        tingkatKesadaranTotal(){
            return (this.isParent.row.aranktk_response_mata||0) + 
            (this.isParent.row.aranktk_response_verbal||0) + 
            (this.isParent.row.aranktk_response_motorik||0)
        },
    },
    data(){
        return {
            pemeriksaanFisik : false,
            isOpenTS: false,
            isOpenHPHT: false,
            disabled: {
                'keluhan-utama': false,
                'riwayat-penyakit-dahulu': false,
                'catatan-dokter': false,
                'alergi-jenis': [],
                'alergi-name': [],
                'tekanan-darah-min': false,
                'tekanan-darah-max': false,
                'nadi': false,
                'nadi-label': false,
                'gula-darah': false,
                'pernafasa': false,
                'spo2': false,
                'suhu': false,
                'berat-badan': false,
                'tinggi': false,
                'lingkar-kepala': false,
                'permukaan-anak': false,
                'pupil': false,
            }
        }
    },
    mounted(){
        this.sockets.subscribe('collaborate_kajian-awal', data => {
            if(data.regId != this.$route.query.regId){
                return
            }

            if(data.type == 'set-disable'){
                if(data.field == 'alergi-jenis'){
                    this.$set(this.disabled['alergi-jenis'], data.index, true)
                }
                else if(data.field == 'alergi-name'){
                    this.$set(this.disabled['alergi-name'], data.index, true)
                }
                else{
                    this.$set(this.disabled, data.field, true)
                }
            }
            else if(data.type == 'set-enable'){
                if(data.field == 'alergi-jenis'){
                    this.$set(this.disabled['alergi-jenis'], data.index, false)
                }
                else if(data.field == 'alergi-name'){
                    this.$set(this.disabled['alergi-name'], data.index, false)
                }
                else{
                    this.$set(this.disabled, data.field, false)
                }
            }
            else if(data.type == 'set-value'){
                if(data.field == 'keluhan-utama'){
                    this.$set(this.isParent.row, 'arankaa_keluhan_utama', data.value)
                }
                else if(data.field == 'catatan-dokter'){
                    this.$set(this.isParent.row, 'arankaa_riwayat_penyakit_sekarang', data.value)
                }
                else if(data.field == 'riwayat-penyakit-dahulu'){
                    this.$set(this.isParent.row, 'arankaa_riwayat_penyakit', data.value)
                }
                else if(data.field == 'has-alergi'){
                    this.$set(this.isParent.row, 'arankaa_has_alergi', data.value)
                    if(!(this.isParent.row.arankaa_alergi||[]).length){
                        this.addAlergi()
                    }
                }
                else if(data.field == 'alergi-jenis'){
                    this.$set(this.isParent.row.arankaa_alergi[data.index], 'jenis', data.value)
                }
                else if(data.field == 'alergi-name'){
                    this.$set(this.isParent.row.arankaa_alergi[data.index], 'name', data.value)
                }
                else if(data.field == 'tekanan-darah-min'){
                    this.$set(this.isParent.row, 'arankaa_ttv_tekanan_darah_min', data.value)
                }
                else if(data.field == 'tekanan-darah-max'){
                    this.$set(this.isParent.row, 'arankaa_ttv_tekanan_darah_max', data.value)
                }
                else if(data.field == 'nadi'){
                    this.$set(this.isParent.row, 'arankaa_ttv_nadi', data.value)
                }
                else if(data.field == 'nadi-label'){
                    this.$set(this.isParent.row, 'arankaa_ttv_label', data.value)
                }
                else if(data.field == 'gula-darah'){
                    this.$set(this.isParent.row, 'arankaa_ttv_gula_darah', data.value)
                }
                else if(data.field == 'pernafasan'){
                    this.$set(this.isParent.row, 'arankaa_ttv_pernafasan', data.value)
                }
                else if(data.field == 'spo2'){
                    this.$set(this.isParent.row, 'arankaa_ttv_spo2', data.value)
                }
                else if(data.field == 'suhu'){
                    this.$set(this.isParent.row, 'arankaa_ttv_suhu', data.value)
                }
                else if(data.field == 'berat-badan'){
                    this.$set(this.isParent.row, 'arankaa_ttv_weight', data.value)
                    this.hitungBMI()
                }
                else if(data.field == 'tinggi'){
                    this.$set(this.isParent.row, 'arankaa_ttv_height', data.value)
                    this.hitungBMI()
                }
                else if(data.field == 'lingkar-kepala'){
                    this.$set(this.isParent.row, 'arankaa_ttv_lingkar_kepala', data.value)
                    this.hitungBMI()
                }
                else if(data.field == 'permukaan-anak'){
                    this.$set(this.isParent.row, 'arankaa_ttv_luas_permukaan_anak', data.value)
                    this.hitungBMI()
                }
                else if(data.field == 'response-mata'){
                    this.$set(this.isParent.row, 'aranktk_response_mata', data.value)
                }
                else if(data.field == 'response-verbal'){
                    this.$set(this.isParent.row, 'aranktk_response_verbal', data.value)
                }
                else if(data.field == 'response-motorik'){
                    this.$set(this.isParent.row, 'aranktk_response_motorik', data.value)
                }
            }
            else if(data.type == 'delete-alergi'){
                this.isParent.row.arankaa_alergi.splice(data.index,1)
            }
            else if(data.type == 'add-alergi'){
                this.isParent.row.arankaa_alergi.push(data.value)
            }

            console.log(data)
        })
    },
    watch:{
        tingkatKesadaranTotal(v){
            setTimeout(()=>{
                this.isParent.row.aranktk_nilai_gcs = v
            },250)
        },
    },
    methods: {
        onFocus(v, field, index = false){
            const regId = this.$route.query.regId
            console.log(v)
            const payload = {
                to: 'kajian-awal',
                type: 'set-disable',
                field: field,
                regId: regId,
                index: index
            }
            this.$socket.emit('collaborate', payload)
        },
        onBlur(v, field, index = false){
            const regId = this.$route.query.regId
            const payload = {
                to: 'kajian-awal',
                type: 'set-enable',
                field: field,
                regId: regId,
                index: index
            }
            this.$socket.emit('collaborate', payload)
        },
        onInput: _.debounce(function(v, field, index = false){
            const regId = this.$route.query.regId
            const payload = {
                to: 'kajian-awal',
                type: 'set-value',
                field: field,
                regId: regId,
                value: v,
                index: index
            }

            this.$socket.emit('collaborate', payload)
        }, 500),

        hitungBeratJanin(){
          this.isParent.row.arankaa_berat_janin = (this.isParent.row.arankaa_tfu - this.isParent.row.arankaa_masuk_panggul) * 155
        },
        addAkultasi(){
            this.isParent.row.arankaa_auskultasi_data.push({
                'auskultasi' : null,
                'janin' : null,
                'letak_punggung' : [],
            })  
        },
        addRiwayatKehamilan(){
          this.isParent.row.arankaa_riwayat_kehamilan.push({
            tahun: null,
            tempat: null,
            umur: null,
            jenis: null,
            penolong: null,
            penyulit: null,
            jk: null,
            keadaan: null
          })
        },
        addRiwayatPenggunaanObat(){
            this.isParent.row.arankaa_riwayat_penggunaan_obat.push({
                'nama': null,
                'dosis': null,
                'penggunaan': null,
            })
        },
        toValidate(val){
            return {...val}
        },
        hitungBMI(){
            if(this.isParent.row.arankaa_ttv_weight && this.isParent.row.arankaa_ttv_height){
                this.isParent.row.arankaa_ttv_bmi = this.isParent.row.arankaa_ttv_weight / ((this.isParent.row.arankaa_ttv_height/100) * (this.isParent.row.arankaa_ttv_height/100))
                this.isParent.row.arankaa_ttv_bmi = this.isParent.row.arankaa_ttv_bmi.toFixed(2) 
            }else{
                this.isParent.row.arankaa_ttv_bmi = null 
            }
        },
        
        searchAlergi: _.debounce(function (e) {
          let data = {
              name: e,
              type: 'get-alergi'
          }
          Gen.apiRest(
              "/do/"+this.isParent.modulePage,
              {data:data}, 
              "POST"
          ).then(res=>{
              this.isParent.mAlergi = res.data.data            
          })
        }, 100),
        
        changeAlergi(e){
            if(!(this.isParent.row.arankaa_alergi||[]).length){
                this.addAlergi()
            }
        },
        
        addAlergi(){
            const data = {
                name : '',
                jenis : null,
            }
            if(!Array.isArray(this.isParent.row.arankaa_alergi)){
                this.$set(this.isParent.row, 'arankaa_alergi', [])
            }

            this.isParent.row.arankaa_alergi.push(data)
            this.addMasterAlergi()
            const payload = {
                to: 'kajian-awal',
                type: 'add-alergi',
                regId: this.$route.query.regId,
                value: data
            }

            this.$socket.emit('collaborate', payload)
        },
        
        addMasterAlergi(){
            for(let i = 0; i < (this.isParent.row.arankaa_alergi||[]).length; i++){
                let newData = this.isParent.row.arankaa_alergi[i]
                if((newData.name||"").length && newData.jenis == "Obat"){
                    let dataPost = {
                        type : 'auto-save-riwayat',
                        name : newData.name
                    }  
                    Gen.apiRest(
                        "/do/"+this.isParent.modulePage,
                        {data:dataPost}, 
                        "POST"
                    )
                }
            }
        },
        
        removeAlergi(k){
            if(!Array.isArray(this.isParent.row.arankaa_alergi)){
                this.$set(this.isParent.row, 'arankaa_alergi', [])
            }

            this.isParent.row.arankaa_alergi.splice(k,1)
            const payload = {
                to: 'kajian-awal',
                type: 'delete-alergi',
                regId: this.$route.query.regId,
                index: k
            }

            this.$socket.emit('collaborate', payload)
        },
        
        changeVital(field, n) {
            if (n == 'N') {
                this.isParent.row[field + '_text'] = 'TAK'
            } else {
                this.isParent.row[field + '_text'] = ''
            }
        },
        
        changeHPL() {
          let dateHPL = moment(this.isParent.row.arankaa_hpht, "DD-MM-YYYY").format('YYYY-M-D')
          let splDate = dateHPL.split("-")
          let day = splDate[2]
          let month = splDate[1]
          let year = splDate[0]

          day = +day + 7
          if (month == 1) {
            month = 10
          } else if (month == 2) {
            month = 11
          } else if (month == 3) {
            month = 12
          } else {
            month = +month - 3
          }
          year = +year + 1

          let daysOfMonth = moment(+year + "-" + month + "-01").endOf("month").format("D")
          daysOfMonth = +daysOfMonth

          let newDay

          if (day > daysOfMonth) {
            newDay = day - daysOfMonth
            month = month + 1
          } else {
            newDay = day
          }

          let newdateHPL = moment((newDay + '-' + month + '-' + year), "DD-MM-YYYY").format('YYYY-MM-DD')
          this.isParent.row.arankaa_hpl = newdateHPL

          
          // Hitung umur kehamilan 
          const specificDate = moment(this.isParent.row.arankaa_hpht)
          const currentDate = moment()
          // Calculate the difference in weeks and days
          const weeksDifference = currentDate.diff(specificDate, "weeks")
          const daysDifference = currentDate.diff(specificDate, "days") % 7

          this.isParent.row.arankaa_umur_kehamilan_minggu = weeksDifference
          this.isParent.row.arankaa_umur_kehamilan_hari = daysDifference
        },
        
        changeHPL2HPHT(){
          let dateHPL = moment(this.isParent.row.arankaa_hpl, "DD-MM-YYYY").format('YYYY-M-D')
         
          let splDate = dateHPL.split("-")
          let day = splDate[2]
          let month = splDate[1]
          let year = splDate[0]

          day = +day - 7
          month = +month + 3

          if (month > 6) {
            year = +year -1         
          } else {
            year = +year
          }

          let daysOfMonth = moment(+year + "-" + month + "-01").endOf("month").format("D")
          daysOfMonth = +daysOfMonth

          let newDay

          if (day > daysOfMonth) {
            newDay = day - daysOfMonth
            month = month + 1
          } else {
            newDay = day
          }

          let newdateHPL = moment((newDay + '-' + month + '-' + year), "DD-MM-YYYY").format('YYYY-MM-DD')

          console.log(newdateHPL)
        },
    },

    destroyed(){
        this.sockets.unsubscribe('collaborate_kajian-awal')
    }

}

</script>