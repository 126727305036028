<template>
    <div>
        <div class="row g-2">
            <div class="col-md-6">
                <div class="card">
                <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Nutrisi</h6>
                </div>
                <div class="card-body p-3">
                    <div class="row gy-2">
                    <div class="col-md-4">
                        <div>
                        <label for="intakeNutrisi">Intake nutrisi lewat<span class="text-danger">*</span></label>
            
                        <b-form-checkbox-group class="checkbox-block"
                            v-model="isParent.row.arankkp_intake_nutrisi"
                            :options="isParent.Config.mr.mediaTerpasangObsgyn" 
                            label="text" :clearable="true" :reduce="v=>v.value"
                            name="intake_nutrisi"
                        ></b-form-checkbox-group>
                        
                        <VValidate 
                            name="Intake nutrisi lewat" 
                            v-model="isParent.row.arankkp_intake_nutrisi" 
                            :rules="{required: 1}"
                        />
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div>
                        <label for="masalahNutrisi">Masalah yang berhubungan dengan nutrisi<span class="text-danger">*</span></label>
                        <v-select placeholder="Pilih Salah Satu" v-model="isParent.row.arankkp_nutrisi_obesitas" :options="isParent.Config.mr.ranapMasalahNutrisi" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                        
                        <VValidate 
                            name="Masalah yang berhubungan dengan nutrisi" 
                            v-model="isParent.row.arankkp_nutrisi_obesitas" 
                            :rules="{required: 1}"
                        />

                        </div>
                    </div>
                    <template v-if="(isParent.row.arankkp_intake_nutrisi||[]).indexOf('L') != -1">
                        <div class="col-md-4">
                        <b-form-input :formatter="normalText" v-model="isParent.row.arankkp_intake_nutrisi_text"  type="text" class="form-control" placeholder="Sebutkan"/>
                        <VValidate 
                            name="Intake nutrisi lewat Lainnya" 
                            v-model="isParent.row.arankkp_intake_nutrisi_text" 
                            :rules="{required: 1, min: 2}"
                        />
                        </div>
                    </template>
                    </div>
                </div>
                </div>
            </div>
            
            <div class="col-md-6">
                <div class="card mb-0">
                <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Elimnasi</h6>
                </div>
                <div class="card-body p-3">
                    <h6 class="font-weight-semibold text-indigo">BAB<span class="text-danger">*</span></h6>
                    <div class="row gy-2">
                    <div class="col-md-6">
                        <div>
                        <label for="kondisiBab">Kondisi<span class="text-danger">*</span></label>
                        <v-select placeholder="Pilih Kondisi" v-model="isParent.row.arankkp_bab_kondisi" :options="isParent.Config.mr.ranapMasalahBAB" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                        </div>
                        <VValidate 
                            name="Kondisi BAB" 
                            v-model="isParent.row.arankkp_bab_kondisi" 
                            :rules="{required: 1}"
                        />
                    </div>
                    <div class="col-md-6">
                        <div>
                        <label for="kondisiBab">Frekuensi BAB / Hari<span class="text-danger">*</span></label>
                        <b-form-input v-model="isParent.row.arankkp_bab_frekuensi" :formatter="normalText" type="text" name="perKeluhan" class="form-control"/>
                        </div>
                        <VValidate 
                            name="Frekuensi BAB / Hari" 
                            v-model="isParent.row.arankkp_bab_frekuensi" 
                            :rules="{required: 1}"
                        />
                    </div>
                    <template v-if="isParent.row.arankkp_bab_kondisi == 'L'">
                        <div class="col-md-6">
                        <b-form-input :formatter="normalText" v-model="isParent.row.arankkp_bab_kondisi_text"  type="text" class="form-control" placeholder="Sebutkan"/>
                        <VValidate 
                            name="Bab Lainnya" 
                            v-model="isParent.row.arankkp_bab_kondisi_text" 
                            :rules="{required: 1, min: 2}"
                        />
                        </div>
                    </template>
                    </div>
                </div>
                <div class="card-body p-3">
                    <h6 class="font-weight-semibold text-indigo">BAK</h6>
                    <div class="row gy-2">
                    <div class="col-md-6">
                        <div>
                        <label for="bakKondisi">Kondisi<span class="text-danger">*</span></label>
                        <v-select placeholder="Pilih Kondisi" v-model="isParent.row.arankkp_bak_kondisi" :options="isParent.Config.mr.ranapMasalahBAK" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                        </div>
                        <VValidate 
                            name="Kondisi BAK" 
                            v-model="isParent.row.arankkp_bak_kondisi" 
                            :rules="{required: 1}"
                        />
                    </div>
                    <div class="col-md-6">
                        <div>
                        <label for="bakKateter">Kateter<span class="text-danger">*</span></label>
                        
                        <v-select placeholder="Pilih Kondisi" v-model="isParent.row.arankkp_bak_frekuensi" :options="isParent.Config.mr.ranapMasalahKateter" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                        </div>
                        <VValidate 
                            name="Kateter" 
                            v-model="isParent.row.arankkp_bak_frekuensi" 
                            :rules="{required: 1}"
                        />
                    </div>
                    
                    <template v-if="isParent.row.arankkp_bak_kondisi == 'L'">
                        <div class="col-md-6">
                        <b-form-input :formatter="normalText" v-model="isParent.row.arankkp_bak_kondisi_text"  type="text" class="form-control" placeholder="Sebutkan"/>
                        <VValidate 
                            name="BaK Lainnya" 
                            v-model="isParent.row.arankkp_bak_kondisi_text" 
                            :rules="{required: 1, min: 2}"
                        />
                        </div>
                    </template>
                    </div>
                </div>
                </div>
            </div>
            <div class="col-12">
                <div class="card">
                <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Proteksi dan Risiko</h6>
                </div>
                <div class="card-body p-3">
                    <div class="row">
                    <div class="col-md-3">
                        <div>
                            <label for="perHamil">Status Mental<strong class="text-danger">*</strong></label>
                            <v-select placeholder="Pilih Status Mental" v-model="isParent.row.arankkp_status_mental" :options="isParent.Config.mr.ranapKPStatusMental" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                        </div>
                        

                        <template v-if="isParent.row.arankkp_status_mental == 'L'">
                            <label class="mt-2" for="perHamil">Status Mental Lainnya<strong class="text-danger">*</strong></label>
                            <b-form-input :formatter="normalText" v-model="isParent.row.arankkp_status_mental_text"  type="text" class="form-control" placeholder="Isi Status Mental Lainnya"/>
                            <VValidate 
                                name="Isi Status Mental Lainnya" 
                                v-model="isParent.row.arankkp_status_mental_text" 
                                :rules="{required: 1, min: 2}"
                            />
                        </template>
                        
                        <VValidate 
                            name="Status Mental" 
                            v-model="isParent.row.arankkp_status_mental" 
                            :rules="{required: 1}"
                        />

                    </div>
                    <!-- Muncul ketika pilih kejang-->
                    <div class="col-md-3" v-if="isParent.row.arankkp_status_mental == 'Kejang'">
                        <div>
                        <label for="pdrStatusMental">Tipe dan Frekuensi</label>                        
                        <b-form-input v-model="isParent.row.arankkp_tipe_frekuensi" :formatter="normalText" type="text" name="perKeluhan" class="form-control" placeholder="Tipe dan Frekuensi" />
                        </div>
                    </div>
                    <!-- End-->
                    </div>
                </div>
                <div class="card-body">
                    <div class="font-weight-semibold mb-2">Pengkajian Restrain</div>
                    <div class="row g-2">
                    <div class="col-md-3">
                        <div>
                        <label for="pdrKajianRestrain">Pengkajian Restrain</label>
                        
                        <v-select placeholder="Pilih Pengkajian Restrain" v-model="isParent.row.arankkp_pengkajian_restrain" :options="isParent.Config.mr.isRestrain" label="text" :clearable="true" :reduce="v=>v.value"></v-select>

                        </div>
                        <!-- Muncul, Jika Pengkajian Restrain ada masalah-->
                        <div class="row mt-2" v-if="isParent.row.arankkp_pengkajian_restrain == 'Y'">
                        <div class="col-md-12">
                            <a href="javascript:;" @click="addRestrain=true" data-toggle="modal" data-target="#PengkajianRestrain"
                            class="btn btn-block btn-sm alpha-info border-info"><i
                                class="icon-plus2 mr-1"></i>Pengkajian Restrain</a>
                        </div>
                        </div>
                        <!-- End-->
                    </div>
                    <div class="col-md-4">
                        <div>
                            <label for="pdrKajianRestrain">Pernah Menggunakan Restrain Sebelumnya?</label>
                            <div>
                                <b-form-radio-group
                                    :options="isParent.Config.mr.yesNoOpt"
                                    v-model="isParent.row.arankkp_is_have_restrain"
                                />
                            </div>
                          
                            <VValidate 
                                name="Pernah Menggunakan Restrain" 
                                v-model="isParent.row.arankkp_is_have_restrain" 
                                :rules="{required: 1}"
                            />
                        </div>
                        <div class="mt-2" v-if="isParent.row.arankkp_is_have_restrain == 'Y'">
                            <label for="">Dimana?</label>
                            <b-form-input v-model="isParent.row.arankkp_lokasi_restrain" :formatter="normalText" type="text" name="perKeluhan" class="form-control" placeholder="Lokasi Restrain Sebelumnya" />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <b-form-checkbox
                            v-model="isParent.row.arankkp_is_edukasi_restrain"
                            value="Y"
                            :unchecked-value="null"
                            >
                            Edukasi dengan keluarga dan pasien untuk penggunaan restrain
                        </b-form-checkbox>
                        <!--
                        <VValidate 
                            name="Edukasi dengan keluarga" 
                            v-model="isParent.row.arankkp_is_edukasi_restrain" 
                            :rules="{required: 1}"
                        />
                        -->
                    </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row g-2">
                    <div class="col-md-3">

                        <div>
                            <label for="perHamil">Pengkajian Risiko Jatuh<strong class="text-danger">*</strong></label>
                            <v-select placeholder="Pilih Pengkajian Risiko Jatuh" v-model="isParent.row.arankkp_pengkajian_resiko_jatuh" :options="isParent.Config.mr.ranapKPRiwayatJatuh" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                        </div>
                        

                        <template v-if="isParent.row.arankkp_pengkajian_resiko_jatuh == 'L'">
                            <label class="mt-2" for="perHamil">Pengkajian Risiko Jatuh Lainnya<strong class="text-danger">*</strong></label>
                            <b-form-input :formatter="normalText" v-model="isParent.row.arankkp_pengkajian_resiko_jatuh_text"  type="text" class="form-control" placeholder="Isi Pengkajian Risiko Jatuh Lainnya"/>
                            <VValidate 
                                name="Isi Pengkajian Risiko Jatuh Lainnya" 
                                v-model="isParent.row.arankkp_pengkajian_resiko_jatuh_text" 
                                :rules="{required: 1, min: 2}"
                            />
                        </template>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div class="col-12">
                <div class="card">
                <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Kebutuhan Komunikasi dan Edukasi</h6>
                </div>
                <div class="card-body p-3">
                    <div class="row">
                        <div class="col-md-3">
                            <div>
                            <label for="kkeHubunganPasien">Hubungan Pasien dengan Keluarga</label>
                            <v-select placeholder="Hubungan Pasien dengan Keluarga" v-model="isParent.row.arankkp_hub_pasien" :options="isParent.Config.mr.baikNoOpt" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                            </div>
                        </div>
                        <div class="w-100 mb-3"></div>
                        <div class="col-md-3">
                            <div>
                                <label for="perHamil">Pekerjaan</label>
                                <v-select placeholder="Pilih Pekerjaan" v-model="isParent.row.arankkp_pekerjaan" :options="isParent.mPekerjaan" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                            </div>

                            <template v-if="isParent.row.arankkp_pekerjaan == 99999">
                                <label class="mt-2" for="perHamil">Pekerjaan Lainnya<strong class="text-danger">*</strong></label>
                                <b-form-input :formatter="normalText" v-model="isParent.row.arankkp_pekerjaan_text"  type="text" class="form-control" placeholder="Isi Pekerjaan Lainnya"/>
                                <VValidate 
                                    name="Isi Pekerjaan Lainnya" 
                                    v-model="isParent.row.arankkp_pekerjaan_text" 
                                    :rules="{required: 1, min: 2}"
                                />
                            </template>
                        </div>
                        <div class="col-md-3">
                            <div>
                                <label for="kkeBimbinganRohani">Bimbingan Rohani? <span style="cursor:help;"
                                v-b-tooltip.hover 
                                title="Apakah pasien memerlukan pelayanan/bimbingan rohani selama dirawat?"><i
                                    class="icon-info22 text-indigo"></i></span></label>
                                <b-form-radio-group
                                    :options="isParent.Config.mr.yesNoOpt"
                                    v-model="isParent.row.arankkp_has_bimbingan_rohani"
                                />
                            </div>
                        </div>
                        <div class="col-md-3" v-if="isParent.row.arankkp_has_bimbingan_rohani == 'Y'">
                            
                            <div>
                                <div class="form-group">
                                <label for="perHamil">Rohaniawan yang dibutuhkan Pasien</label>
                                <v-select placeholder="Pilih Rohaniawan" v-model="isParent.row.arankkp_rohanian" :options="isParent.mAgama" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                <VValidate 
                                    name="Rohaniawan Pasien" 
                                    v-model="isParent.row.arankkp_rohanian" 
                                    :rules="{required: 1}"
                                />
                                </div>
                            </div>
                            <div class="mt-2" v-if="isParent.row.arankkp_rohanian  == 99999">
                                <div class="form-group">
                                <label for="perHamil">Rohaniawan yang dibutuhkan Pasien Lainnya<strong class="text-danger">*</strong></label>
                                <b-form-input v-model="isParent.row.arankkp_rohanian_text" :formatter="normalText" type="text" name="perKeluhan" class="form-control" placeholder="Rohaniawan yang dibutuhkan Pasien Lainnya" />
                                <VValidate 
                                    name="Rohaniawan yang dibutuhkan Pasien Lainnya" 
                                    v-model="isParent.row.arankkp_rohanian_text" 
                                    :rules="{required: 1}"
                                />
                                </div>
                            </div>

                            <div class="mt-2">
                            <textarea v-model="isParent.row.arankkp_rohanian_desc" rows="2" class="form-control"></textarea>
                            </div>
                        </div>
                        <div class="w-100"></div>
                        <div class="col-md-5 mt-2">
                            <div>
                            <label for="kkeKeyakinan">Sebutkan nilai/keyakinan keluarga</label>
                            <textarea v-model="isParent.row.arankkp_nilai_keyakinan" name="kkeKeyakinan" id="kkeKeyakinan" cols="30" rows="4"
                                class="form-control"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-md-6">
                            <div class="card mb-0">
                            <div class="card-header bg_head_panel">
                                <h6 class="card-title font-weight-semibold">Kebutuhan Psiko</h6>
                            </div>
                            <div class="card-body p-3">
                                <div>
                                <label for="perHub">Status Psikologis Pasien<strong class="text-danger">*</strong></label>
                                <div>
                                    <b-form-radio
                                        v-for="v in isParent.Config.mr.psikologiPot"
                                        inline
                                        class="mb-1"
                                        v-model="isParent.row.arankaa_status_psikologi"
                                        :key="v.value"
                                        :value="v.value"
                                        @input="inputPsikolog($event)"
                                    >{{ v.text }}</b-form-radio>
                                    <div class="mt-2" v-if="isParent.row.arankaa_status_psikologi == 6">
                                        <b-form-input :formatter="normalText" v-model="isParent.row.arankaa_status_psikologi_lainnya"  type="text" class="form-control" placeholder="Isi psikologis pasien"/>
                                        <VValidate 
                                            name="Psikologi Pasien Lainnya" 
                                            v-model="isParent.row.arankaa_status_psikologi_lainnya" 
                                            :rules="toValidate(isParent.mrValidation.arankaa_status_psikologi_lainnya)"
                                        />
                                    </div>
                                    
                                    <VValidate 
                                        name="Psikologi Pasien" 
                                        v-model="isParent.row.arankaa_status_psikologi" 
                                        :rules="toValidate(isParent.mrValidation.arankaa_status_psikologi)"
                                    />
                                </div>
                                </div>
                                <div class="row">
                                <div class="col-md-6">
                                    <div class="mt-3">
                                    <label for="kkeStatusMental">Status Mental</label>
                                    <div>
                                        <b-form-group>
                                            <b-form-checkbox-group
                                                v-model="isParent.row.arankaa_status_mental"
                                                :options="Config.mr.ranapStatusMental"
                                                name="bahasa"
                                            ></b-form-checkbox-group>
                                        </b-form-group>
                                        
                                        <VValidate 
                                            name="Status Mental Pasien" 
                                            v-model="isParent.row.arankaa_status_mental" 
                                            :rules="toValidate(isParent.mrValidation.arankaa_status_mental)"
                                        />
                                    </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                
                                    <div class="mt-3" v-if="(isParent.row.arankaa_status_mental||[]).indexOf('Ada kelainan perilaku, sebutkan') != -1">
                                    <label for="kkePerilakuKekerasan">Kelainan Perilaku</label>
                                    <b-form-textarea v-model="isParent.row.arankaa_kelainan_perilaku" :formatter="normalText"  name="perRiwayat" class="form-control" 
                                    placeholder="Kelainan perilaku"></b-form-textarea>                    
                                    </div>

                                    <div class="mt-3" v-if="(isParent.row.arankaa_status_mental||[]).indexOf('Ada perilaku kekerasan yang dialami pasien sebelumnya') != -1">
                                    <label for="kkePerilakuKekerasan">Perilaku kekerasan yang dialami pasien
                                        sebelumnya</label>
                                    
                                    <b-form-textarea v-model="isParent.row.arankaa_perilaku_kekerasan" :formatter="normalText"  name="perRiwayat" class="form-control" 
                                    placeholder="Perilaku kekerasan yang dialami pasien sebelumnya"></b-form-textarea>
                                    
                                    </div>
                                </div>
                                </div>


                            </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="card mb-0 h-100">
                            <div class="card-header bg_head_panel">
                                <h6 class="card-title font-weight-semibold">Kebutuhan Sosio</h6>
                            </div>
                            <div class="card-body p-3">
                                <div class="row">
                                <div class="col-md-6">
                                    <div>
                                        <label for="perHamil">Pendidikan Pasien<strong class="text-danger">*</strong></label>
                                        <v-select placeholder="Pilih Pendidikan" v-model="isParent.row.arankaa_pendidikan" :options="isParent.mPendidikan" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                    </div>
                                    
                                    <VValidate 
                                        name="Pendidikan Pasien" 
                                        v-model="isParent.row.arankaa_pendidikan" 
                                        :rules="{required: 1}"
                                    />

                                </div>
                                <div class="col-md-6 col-lg-6">
                                    <div>
                                        <label for="perHamil">Pekerjaan Pasien<strong class="text-danger">*</strong></label>
                                        <v-select placeholder="Pilih Pekerjaan" v-model="isParent.row.arankaa_pekerjaan" :options="isParent.mPekerjaan" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                    </div>
                                    
                                    <VValidate 
                                        name="Pekerjaan Pasien" 
                                        v-model="isParent.row.arankaa_pekerjaan" 
                                        :rules="{required: 1}"
                                    />

                                    <template v-if="isParent.row.arankaa_pekerjaan == 99999">
                                        <label class="mt-2" for="perHamil">Pekerjaan Lainnya<strong class="text-danger">*</strong></label>
                                        <b-form-input :formatter="normalText" v-model="isParent.row.arankaa_pekerjaan_lainnya"  type="text" class="form-control" placeholder="Isi Pekerjaan Lainnya"/>
                                        <VValidate 
                                            name="Isi Pekerjaan Lainnya" 
                                            v-model="isParent.row.arankaa_pekerjaan_lainnya" 
                                            :rules="{required: 1, min: 2}"
                                        />
                                    </template>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="card mb-0">
                            <div class="card-header bg_head_panel">
                                <h6 class="card-title font-weight-semibold">Spiritual</h6>
                            </div>
                            <div class="card-body p-3">
                                <div class="row">
                                
                                <div class="col-md-3">
                                    <div class="form-group">
                                    <label for="perHamil">Agama Pasien<strong class="text-danger">*</strong></label>
                                    <v-select placeholder="Pilih Agama" v-model="isParent.row.arankaa_agama" :options="isParent.mAgama" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                    <VValidate 
                                        name="Agama Pasien" 
                                        v-model="isParent.row.arankaa_agama" 
                                        :rules="{required: 1}"
                                    />
                                    </div>
                                </div>
                                <div class="col-md-3" v-if="isParent.row.arankaa_agama  == 99999">
                                    <div class="form-group">
                                    <label for="perHamil">Agama Pasien Lainnya<strong class="text-danger">*</strong></label>
                                    <b-form-input v-model="isParent.row.arankaa_agama_lainnya" :formatter="normalText" type="text" name="perKeluhan" class="form-control" placeholder="Agama Pasien Lainnya" />
                                    <VValidate 
                                        name="Agama Pasien Lainnya" 
                                        v-model="isParent.row.arankaa_agama_lainnya" 
                                        :rules="{required: 1}"
                                    />
                                    </div>
                                </div>

                                <div class="w-100"></div>
                                <div class="col-md-5">
                                    <div class="form-group">
                                    <label for="spiKeyakinan">Keyakinan</label>
                                    <b-form-textarea :formatter="normalText" v-model="isParent.row.arankaa_keyakinan" name="spiKeyakinan" id="spiKeyakinan" rows="3" class="form-control"></b-form-textarea>
                                    </div>
                                </div>
                                <div class="col-md-5">
                                    <div class="form-group">
                                    <label for="spiNilai">Nilai-nilai Pasien atau Keluarga</label>
                                    <b-form-textarea :formatter="normalText" v-model="isParent.row.arankaa_nilai" name="spiNilai" id="spiNilai" rows="3" class="form-control"></b-form-textarea>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>

        <b-modal v-model="addRestrain" :title="'Asesmen Restrain'" size="xl" ok-only ok-title="Simpan">
            <div class="row">
                <div class="col-12">
                    <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                        <h6 class="card-title font-weight-semibold">Tanda Tanda Vital</h6>
                    </div>
                
                    <div class="card-body p-3">
                        <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                            <label>Tekanan Darah <span class="text-danger">*</span></label>
                            <div class="input-group">
                                <b-form-input :formatter="number" v-model="isParent.row.arankkp_ttv_tekanan_darah_min" type="text" name="name" class="form-control" placeholder="Systole"/>
                                
                                <div class="input-group-append input-group-prepend"><span class="input-group-text">/</span></div>

                                <b-form-input :formatter="number" placeholder="Diastole" v-model="isParent.row.arankkp_ttv_tekanan_darah_max" type="text" class="form-control" />
                                <div class="input-group-append"><span class="input-group-text">mmHG</span></div>
                            </div>      

                            <VValidate 
                                name="Tekanan Darah Min" 
                                v-model="isParent.row.arankkp_ttv_tekanan_darah_min" 
                                :rules="toValidate(isParent.mrValidation.arankkp_ttv_tekanan_darah_min)"
                            />
                            <VValidate 
                                name="Tekanan Darah Max" 
                                v-model="isParent.row.arankkp_ttv_tekanan_darah_max" 
                                :rules="toValidate(isParent.mrValidation.arankkp_ttv_tekanan_darah_max)"
                            />
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="form-group">
                            <label>Ukuran Pupil </label>
                            <div class="form-row">
                                <div class="col-md-12">
                                <div class="input-group">
                                    <b-form-input :formatter="number" v-model="isParent.row.arankkp_ttv_ukuran_pupil"  type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">mm/Ki</span>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <VValidate 
                                name="Ukuran Pupil" 
                                v-model="isParent.row.arankkp_ttv_ukuran_pupil" 
                                :rules="toValidate(isParent.mrValidation.arankkp_ttv_ukuran_pupil)"
                            />
                            </div>

                        </div>

                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Nadi <span class="text-danger">*</span></label>
                                <div class="form-row">
                                    <div class="col-md-12">
                                    <div class="input-group">
                                        <b-form-input :formatter="number" v-model="isParent.row.arankkp_ttv_nadi" type="text" class="form-control" />
                                        <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                                        <div class="input-group-append">
                                            <div style="width: 140px;">
                                            <v-select placeholder="Pilih Label" v-model="isParent.row.arankkp_ttv_label" :options="Config.mr.StatusRegular" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>

                                <VValidate 
                                    name="Nadi" 
                                    v-model="isParent.row.arankkp_ttv_nadi" 
                                    :rules="toValidate(isParent.mrValidation.arankkp_ttv_nadi)"
                                />
                                <VValidate 
                                    name="Label" 
                                    v-model="isParent.row.arankkp_ttv_label" 
                                    :rules="toValidate(isParent.mrValidation.arankkp_ttv_label)"
                                />
                            </div>
                        </div>
                        
                        <div class="col-md-2">
                            <div class="form-group">
                            <label>GDA</label>
                            <div class="form-row">
                                <div class="col-md-12">
                                <div class="input-group">
                                    <b-form-input :formatter="alphanum" v-model="isParent.row.arankkp_ttv_gula_darah"  type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">mg/dL</span>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <VValidate 
                                name="Gula Darah" 
                                v-model="isParent.row.arankkp_ttv_gula_darah" 
                                :rules="toValidate(isParent.mrValidation.arankkp_ttv_gula_darah)"
                            />
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Pernafasan<span class="text-danger">*</span></label>
                                <div class="input-group">
                                    <b-form-input :formatter="number" v-model="isParent.row.arankkp_ttv_pernafasan"  type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                                </div>
        
                                <VValidate 
                                    name="Pernafasan" 
                                    v-model="isParent.row.arankkp_ttv_pernafasan" 
                                    :rules="toValidate(isParent.mrValidation.arankkp_ttv_pernafasan)"
                                />
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="form-group">
                            <label>SPO2</label>
                            <div class="form-row">
                                <div class="col-md-12">
                                <div class="input-group">
                                    <b-form-input :formatter="number" v-model="isParent.row.arankkp_ttv_spo2"  type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">%</span>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <VValidate 
                                name="SPO2" 
                                v-model="isParent.row.arankkp_ttv_spo2" 
                                :rules="toValidate(isParent.mrValidation.arankkp_ttv_spo2)"
                            />
                            </div>
                        </div>

                        <div class="col-md-2">
                            <div class="form-group">
                            <label>Suhu<span class="text-danger">*</span></label>
                            <div class="form-row">

                                <div class="col-md-12">
                                <div class="input-group">
                                    <b-form-input :formatter="number" v-model="isParent.row.arankkp_ttv_suhu"  type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">C</span>
                                    </div>
                                </div>
                                </div>
                            </div>
                                                       
                            <VValidate 
                                name="Suhu" 
                                v-model="isParent.row.arankkp_ttv_suhu" 
                                :rules="toValidate(isParent.mrValidation.arankkp_ttv_suhu)"
                            />
                            </div>
                        </div>

                        <div class="col-md-2">
                            <div class="form-group">
                            <label>Berat Badan</label>
                            <div class="form-row">

                                <div class="col-md-12">
                                <div class="input-group">
                                    <b-form-input :formatter="number" v-model="isParent.row.arankkp_ttv_weight"  type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">kg</span>
                                    </div>
                                </div>
                                </div>
                            </div>
                                                                       
                            <VValidate 
                                name="Berat Badan" 
                                v-model="isParent.row.arankkp_ttv_weight" 
                                :rules="toValidate(isParent.mrValidation.arankkp_ttv_weight)"
                            />
                            </div>
                        </div>

                        
                        <div class="col-md-2">
                            <div class="form-group">
                            <label>Tinggi</label>
                            <div class="form-row">

                                <div class="col-md-12">
                                <div class="input-group">
                                    <b-form-input @input="hitungBMI()"  :formatter="number" v-model="isParent.row.arankkp_ttv_height"  type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">cm</span>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <VValidate 
                                name="Tinggi Badan" 
                                v-model="isParent.row.arankkp_ttv_height" 
                                :rules="toValidate(isParent.mrValidation.arankkp_ttv_height)"
                            />
                            </div>
                        </div>

                        <div class="col-md-2">
                            <div class="form-group">
                            <label>Lingkar Kepala</label>
                            <div class="form-row">

                                <div class="col-md-12">
                                <div class="input-group">
                                    <b-form-input :formatter="number" v-model="isParent.row.arankkp_ttv_lingkar_kepala"  type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">cm</span>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <VValidate 
                                name="Lingkar Kepala" 
                                v-model="isParent.row.arankkp_ttv_lingkar_kepala" 
                                :rules="toValidate(isParent.mrValidation.arankkp_ttv_lingkar_kepala)"
                            />
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="form-group">
                            <label>BMI</label>
                            <div class="form-row">
                                <div class="col-md-12">
                                <div class="input-group">
                                    <b-form-input disabled v-model="isParent.row.arankkp_ttv_bmi"  type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">m2</span>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>

                        <div class="col-md-3" v-if="isParent.row.ap_usia <= 15">
                            <div class="form-group">
                            <label>Luas Permukaan Tubuh Anak</label>
                            <div class="input-group">
                                <b-form-input :formatter="number" v-model="isParent.row.arankkp_ttv_luas_permukaan_anak"  type="text" class="form-control" />
                                <div class="input-group-append"><span class="input-group-text">m<sup>2</sup></span></div>
                            </div>
                            </div>
                        </div>

                        <!--
                        <div class="col-md-3">
                            <div class="form-group">
                            <label>Kesadaran</label>
                            <div class="form-row">
                                <div class="col-md-12">
                                    <v-select placeholder="Pilih Kesadaran" v-model="isParent.row.arankkp_ttv_kesadaran" :options="isParent.mKesadaran" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                </div>
                            </div>
                            <VValidate 
                                name="Kesadaran" 
                                v-model="isParent.row.arankkp_ttv_kesadaran" 
                                :rules="toValidate(isParent.mrValidation.arankkp_ttv_kesadaran)"
                            />
                            </div>
                        </div>
                        -->

                        <!--
                        <div class="col-md-3" v-if="!isParent.row.arankkp_ttv_bmi">
                            <div class="form-group">
                                <label>IMT</label>
                                <b-form-input v-model="isParent.row.arankkp_ttv_imt" type="text"
                                class="form-control" />
                            </div>
                        </div>
                        -->

                        </div>
                    </div>

                    </div>
                </div>

                <div class="col-4 mt-2">
                    <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                        <h6 class="card-title font-weight-semibold">Hasil Observasi</h6>
                    </div>
                    <div class="card-body p-3">
                        <div class="row">
                        <div class="col-md-12">
                            <b-form-group>
                                <b-form-checkbox-group class="checkbox-block"
                                    v-model="isParent.row.arankkp_hasil_observasi"
                                    :options="isParent.Config.mr.ranapResHasilObservasi"
                                    name="hasil_observasi"
                                ></b-form-checkbox-group>
                            </b-form-group>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>

                <div class="col-4 mt-2">
                    <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                        <h6 class="card-title font-weight-semibold">Pertimbangan Klinis</h6>
                    </div>
                    <div class="card-body p-3">
                        <div class="row">
                            <b-form-group>
                                <b-form-checkbox-group class="checkbox-block"
                                    v-model="isParent.row.arankkp_hasil_klinis"
                                    :options="isParent.Config.mr.ranapResPertimbanganKlinis"
                                    name="hasil_klinis"
                                ></b-form-checkbox-group>
                            </b-form-group>
                        </div>
                    </div>
                    </div>
                </div>

                <div class="col-4 mt-2">
                    <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                        <h6 class="card-title font-weight-semibold">Penilaian dan Order Dokter</h6>
                    </div>
                    <div class="card-body p-3">
                        <div class="row">
                        <div class="col-md-12">
                            <b-form-group>
                                <b-form-checkbox-group class="checkbox-block"
                                    v-model="isParent.row.arankkp_hasil_penilaian"
                                    :options="isParent.Config.mr.ranapResPenilaianOrder"
                                    name="hasil_penilaian"
                                ></b-form-checkbox-group>
                            </b-form-group>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="card">
                    <div class="card-header bg_head_panel">
                        <h5 class="card-title font-weight-semibold">Tingkat Kesadaran (GCS)</h5>
                    </div>
                    <div class="card-body mt-2">
                        <table class="table table-bordered table-hover">
                        <thead>
                            <tr>
                            <th>Pengkajian</th>
                            <th>Nilai</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td class="table-warning" rowspan="4"><span class="font-weight-semibold">Respons Mata</span></td>
                            
                            <!-- ele pertama hardcode -->
                            <td>
                            <b-form-radio v-model="isParent.row.arankkp_tingkat_kesadaran_mata" name="arankkp_tingkat_kesadaran_mata" :value="4">Dapat Membuka Mata Secara Spontan  <span class="text-blue-800 font-weight-semibold">(4)</span></b-form-radio>
                            </td>

                            </tr>
                            <template v-for="(v,k) in (Config.mr.ksResponseMata||[])">
                                <tr v-if="k != 0" :key="k">
                                <td>
                                    <b-form-radio v-model="isParent.row.arankkp_tingkat_kesadaran_mata" name="arankkp_tingkat_kesadaran_mata" :value="v.value">{{v.text}}<span class="text-blue-800 font-weight-semibold">({{v.value}})</span></b-form-radio>
                                </td>
                                </tr>
                            </template>
                        </tbody>
                        <tbody>
                            <tr>
                            <td class="table-warning" rowspan="5"><span class="font-weight-semibold">Respons Verbal</span></td>
                            
                            <td>
                            <b-form-radio v-model="isParent.row.arankkp_tingkat_kesadaran_verbal" name="arankkp_tingkat_kesadaran_verbal" :value="5">Pasien Sadar dan Merespons Pertanyaan dengan Benar<span class="text-blue-800 font-weight-semibold">(5)</span>
                            </b-form-radio>
                            </td>
                            
                            </tr>
                            <template v-for="(v,k) in (Config.mr.ksResponseVerbal||[])">
                                <tr v-if="k != 0" :key="k">
                                <td>
                                    <b-form-radio v-model="isParent.row.arankkp_tingkat_kesadaran_verbal" name="arankkp_tingkat_kesadaran_verbal" :value="v.value">{{v.text}}<span class="text-blue-800 font-weight-semibold">({{v.value}})</span></b-form-radio>
                                </td>
                                </tr>
                            </template>
                        </tbody>
                        <tbody>
                            <tr>
                            <td class="table-warning" rowspan="6"><span class="font-weight-semibold">Respons Motorik</span></td>
                            
                            <td>
                            <b-form-radio v-model="isParent.row.arankkp_tingkat_kesadaran_motorik" name="arankkp_tingkat_kesadaran_motorik" :value="6">Pasien Dapat Melakukan Gerakan Sesuai Perintah<span class="text-blue-800 font-weight-semibold">(6)</span></b-form-radio>
                            </td>
                            
                            </tr>
                            <template v-for="(v,k) in (Config.mr.ksResponseMotorik||[])">
                                <tr v-if="k != 0" :key="k">
                                <td>
                                    <b-form-radio v-model="isParent.row.arankkp_tingkat_kesadaran_motorik" name="arankkp_tingkat_kesadaran_motorik" :value="v.value">{{v.text}}<span class="text-blue-800 font-weight-semibold">({{v.value}})</span></b-form-radio>
                                </td>
                                </tr>
                            </template>
                        </tbody>
                        <tfoot>
                            <tr>
                            <td class="table-info" colspan="9">
                                <span class="font-weight-semibold">Nilai GCS: {{tingkatKesadaranTotal}}</span>
                                <span v-if="tingkatKesadaranTotal >= 14" class="font-weight-semibold border-left ml-2 pl-2"> Composmentis</span> 
                                <span v-else-if="tingkatKesadaranTotal >= 12 && tingkatKesadaranTotal <= 13" class="font-weight-semibold border-left ml-2 pl-2">Apatis</span>
                                <span v-else-if="tingkatKesadaranTotal >= 10 && tingkatKesadaranTotal <= 11" class="font-weight-semibold border-left ml-2 pl-2">Delirium</span>
                                <span v-else-if="tingkatKesadaranTotal >= 7 && tingkatKesadaranTotal <= 9" class="font-weight-semibold border-left ml-2 pl-2">Somonolen</span>
                                <span v-else-if="tingkatKesadaranTotal >= 5 && tingkatKesadaranTotal <= 6" class="font-weight-semibold border-left ml-2 pl-2">Sopor</span>
                                <span v-else-if="tingkatKesadaranTotal >= 4 && tingkatKesadaranTotal <= 4" class="font-weight-semibold border-left ml-2 pl-2">Semi-Comma</span>
                                <span v-else class="font-weight-semibold border-left ml-2 pl-2">Coma</span>
                            </td>
                            </tr>
                        </tfoot>
                        </table>
                    </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="card">
                    <div class="card-body table-success">
                        <b-form-checkbox
                            v-model="isParent.row.arankkp_kel_is_setuju"
                            value="Y"
                            :unchecked-value="null"
                            >
                            Keluarga sudah dijelaskan tentang restrain
                        </b-form-checkbox>
                        <VValidate 
                            name="DiJelaskan Restrain" 
                            v-model="isParent.row.arankkp_kel_is_setuju" 
                            :rules="{required: 1}"
                        />
                    </div>
                    </div>

                </div>
            </div>
        </b-modal>
    </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')


export default{
    extends: GlobalVue,
    computed: {
        isParent(){
            return this.$parent.$parent.$parent.$parent
        },
        tingkatKesadaranTotal(){
            return (this.isParent.row.arankkp_tingkat_kesadaran_mata||0) + 
            (this.isParent.row.arankkp_tingkat_kesadaran_verbal||0) + 
            (this.isParent.row.arankkp_tingkat_kesadaran_motorik||0)
        },
    },
    data(){
        return {
            addRestrain : false,
        }
    },
    methods: {
        toValidate(val){
            return {...val}
        },
        hitungBMI(){
            if(this.isParent.row.arankkp_ttv_weight && this.isParent.row.arankkp_ttv_height){
                this.isParent.row.arankkp_ttv_bmi = this.isParent.row.arankkp_ttv_weight / ((this.isParent.row.arankkp_ttv_height/100) * (this.isParent.row.arankkp_ttv_height/100))
                this.isParent.row.arankkp_ttv_bmi = this.isParent.row.arankkp_ttv_bmi.toFixed(2)  
            }else{
                this.isParent.row.arankkp_ttv_bmi = null 
            }
        },
        inputPsikolog(e){
            if(this.isParent.row.arankaa_status_psikologi == 6){
                this.isParent.row.arankaa_status_psikologi_text = ''
            }
        },
    },
    watch:{
        tingkatKesadaranTotal(v){
            setTimeout(()=>{
                this.isParent.row.arankkp_tingkat_kesadaran = v
            },250)
        },
        
        // addRestrain(v){
        //     if(v){
        //         this.isParent.row.arankkp_ttv_tekanan_darah_min = this.isParent.row.arankkp_ttv_tekanan_darah_min ||this.isParent.row.arankaa_ttv_tekanan_darah_min
        //         this.isParent.row.arankkp_ttv_tekanan_darah_max = this.isParent.row.arankkp_ttv_tekanan_darah_max ||this.isParent.row.arankaa_ttv_tekanan_darah_max
        //         this.isParent.row.arankkp_ttv_ukuran_pupil = this.isParent.row.arankkp_ttv_ukuran_pupil ||this.isParent.row.arankaa_ttv_ukuran_pupil
        //         this.isParent.row.arankkp_ttv_nadi = this.isParent.row.arankkp_ttv_nadi ||this.isParent.row.arankaa_ttv_nadi
        //         this.isParent.row.arankkp_ttv_label = this.isParent.row.arankkp_ttv_label ||this.isParent.row.arankaa_ttv_label
        //         this.isParent.row.arankkp_ttv_gula_darah = this.isParent.row.arankkp_ttv_gula_darah ||this.isParent.row.arankaa_ttv_gula_darah
        //         this.isParent.row.arankkp_ttv_pernafasan = this.isParent.row.arankkp_ttv_pernafasan ||this.isParent.row.arankaa_ttv_pernafasan
        //         this.isParent.row.arankkp_ttv_spo2 = this.isParent.row.arankkp_ttv_spo2 ||this.isParent.row.arankaa_ttv_spo2
        //         this.isParent.row.arankkp_ttv_suhu = this.isParent.row.arankkp_ttv_suhu ||this.isParent.row.arankaa_ttv_suhu
        //         this.isParent.row.arankkp_ttv_weight = this.isParent.row.arankkp_ttv_weight ||this.isParent.row.arankaa_ttv_weight
        //         this.isParent.row.arankkp_ttv_height = this.isParent.row.arankkp_ttv_height ||this.isParent.row.arankaa_ttv_height
        //         this.isParent.row.arankkp_ttv_lingkar_kepala = this.isParent.row.arankkp_ttv_lingkar_kepala ||this.isParent.row.arankaa_ttv_lingkar_kepala
        //         this.isParent.row.arankkp_ttv_bmi = this.isParent.row.arankkp_ttv_bmi ||this.isParent.row.arankaa_ttv_bmi
        //         this.isParent.row.arankkp_ttv_kesadaran = this.isParent.row.arankkp_ttv_kesadaran ||this.isParent.row.arankaa_ttv_kesadaran
        //         this.isParent.row.arankkp_ttv_luas_permukaan_anak = this.isParent.row.arankkp_ttv_luas_permukaan_anak ||this.isParent.row.arankaa_ttv_luas_permukaan_anak
        //         // autofill ttv
        //     }
        // }
    }
}

</script>